import { useEffect, useRef } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'

import { cacheFirst, imageURLs, sounds } from './map'
import { precachedAssets } from './service-worker'
import Game from './Game'
import Gui from './Gui'
import Menu from './Menu'
import { useState } from 'react'
import AttributionPage from './AttributionPage'
import LaunchScreen from './LaunchScreen'

const backgroundStyle = {
	height: "100vh",
	width: "100vw",
	backgroundColor: "black",
	overflow: "hidden",
	position: "relative",
}

const App = () => {
	const [showLaunch, setShowLaunch] = useState(true)
	const audioRef = useRef()

	const playBackgroundMusic = async (song) => {

		try {

			audioRef.current = new Audio(song)
			var soundContent = await cacheFirst(song)
			audioRef.current = new Audio()
			audioRef.current.src = URL.createObjectURL(soundContent)
			audioRef.current.load()

			audioRef.current.loop = TextTrackCue
			await audioRef.current.play()
		} catch (e) {

		}
	}

	const clickLaunch = () => {
		setShowLaunch(false)
		playBackgroundMusic(sounds.themesong)
	}
	// // Preload resources
	// useEffect(() => {
	// 	async function fetchData() {
	// 		precachedAssets.forEach(async assetToCache => {
	// 			await cacheFirst(assetToCache)
	// 		})
	// 	}

	// 	fetchData()
	// }, [])

	return <MemoryRouter
		initialEntries={[
			{ pathname: '/start/' },
			{ pathname: '/attribution/' },
			{ pathname: '/' }
		]}
		initialIndex={2}
	>
		<div style={backgroundStyle} >
			<Switch>
				<Route exact path={"/start/"} >
					<Gui background={imageURLs.gui} audioRef={audioRef} playBackgroundMusic={playBackgroundMusic} >
						<Game />
					</Gui>
				</Route>
				<Route exact path={"/attribution/"}>
					<Gui background={imageURLs.menu_background}>
						<AttributionPage />
					</Gui>
				</Route>
				<Route exact path={"/"}>
					<Gui background={imageURLs.menu_background} >
						<LaunchScreen onClick={clickLaunch} visible={showLaunch} />
						<Menu />
					</Gui>
				</Route>
			</Switch>
		</div>
	</MemoryRouter>
}

export default App