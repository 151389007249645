import { gameMap } from "./gameMap"
import { reactionTypes, characters, sounds } from "./map"

export const doorOpen = (id, node) => {
	return dialogToNavigate(id, characters.empty, ["The door opened."], node, true)
}

export const dialogToNavigate = (id, character, text, location, door) => {
	if ((!gameMap.some(node => node.id === location))) {
		console.error("No node in GameMap.js with id: ", location)
	}

	const clickSound = door ? [{
		type: reactionTypes.sound,
		content: sounds.click,
	}] : []

	return customDialog(id, [{ character, text: text }], [], null, null, [{
		type: reactionTypes.navigate,
		content: location,
	}], clickSound)
}

export const itemDescription = (id, text) => {
	return simpleDialog(id, characters.empty, text)
}

export const simpleDialog = (id, character, text) => {
	return simpleDialogProgress(id, character, text, [])
}

export const simpleDialogProgress = (id, character, text, progression) => {
	return customDialog(id, [{ character, text: text }], progression, null, null, [], [])
}

export const complexDialog = (id, dialogs, progression, progressItem, destination) => {
	return customDialog(id, dialogs, progression, progressItem, destination, [], [])
}

export const customDialog = (id, dialogs, progression, progressItem, destination, postActions, preActions) => {
	if (!id) {
		console.error("Missing id: ", dialogs, progression, progressItem, destination, postActions, preActions)
	}
	return {
		id: id,
		reactions: preActions.concat([customDialogBase(dialogs, progression, progressItem, destination, postActions)])
	}
}

export const runOnceCustom = (id, dialogs, progression, progressItem, destination, postActions, override) => {
	if (!id) {
		console.error("Missing id: ", dialogs, progression, progressItem, destination, postActions, override)
	}
	return {
		id: id,
		reactions: [{
			type: reactionTypes.runOnce,
			override: override,
			content: {
				id: id.toString(),
				reaction: customDialogBase(dialogs, progression, progressItem, destination, postActions)
			}
		}]
	}
}

export const customDialogBase = (dialogs, progression, progressItem, destination, postActions) => {

	const postActionsList = progression
		.map(prog => changeViewIndex(prog))
		.concat(destination ? [{
			type: reactionTypes.navigateVersion,
			content: viewIdVersion(destination.viewID, destination.version)
		}] : [])
		.concat(postActions)

	return {
		type: reactionTypes.startDialog,
		content: {
			dialogs: dialogs.map(d => dialog(d)),
			postActions: postActionsList
		}
	}
}

export const dialog = (spec) => {
	return {
		...spec.character,
		text: spec.text
	}
}

export const changeViewIndex = (change) => {

	return {
		type: reactionTypes.runOnce,
		content: {
			id: `${change.desc}-${change.viewID}-${change.version}`,
			reaction: {
				description: change.desc,
				type: reactionTypes.changeViewIndex,
				content: viewIdVersion(change.viewID, change.version)
			}
		}
	}
}

export const viewIdVersion = (viewID, version) => {
	if (!gameMap.some(node => (node.id === viewID && node.version === version))) {
		console.error(`No node-version in gameMap.js for: viewId: ${viewID}, version: ${version}`)
	}

	return {
		viewID: viewID,
		version: version,
	}
}