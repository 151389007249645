import { locations, viewBackgrounds, imageURLs, reactionTypes, hoverImages } from './map'

export const gameMap = [
	{
		id: locations.v001,
		version: 0,
		imageURL: viewBackgrounds.v001,
		return: locations.v002,
		onEntryId: "entry_first_view",
		clickableObjects: [
			{
				x: 7.54,
				y: 10.840000000000003,
				width: 12.43,
				height: 37.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "assistantInitial",
			},
			{
				x: 21.75,
				y: 15.39,
				width: 13.17,
				height: 36.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "sandersInitial",
			},
			{
				x: 54.14,
				y: 18.539999999999992,
				width: 42.75,
				height: 55.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "navigationComputer",
			},
		]
	},
	{
		id: locations.v001,
		version: 1,
		imageURL: viewBackgrounds.v001,
		return: locations.v002,
		clickableObjects: [
			{
				x: 7.54,
				y: 10.840000000000003,
				width: 12.43,
				height: 37.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "assistantNotLookGood",
			},
			{
				x: 21.75,
				y: 15.39,
				width: 13.17,
				height: 36.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "sandersGivesP1",
			},
			{
				x: 54.14,
				y: 18.539999999999992,
				width: 42.75,
				height: 55.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "navigationComputer",
			},
		]
	},
	{
		id: locations.v001,
		version: 2,
		imageURL: viewBackgrounds.v001,
		return: locations.v002,
		clickableObjects: [
			{
				x: 7.54,
				y: 10.840000000000003,
				width: 12.43,
				height: 37.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "assistantNotLookGood",
			},
			{
				x: 21.75,
				y: 15.39,
				width: 13.17,
				height: 36.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "sandersHasGivenP1",
			},
			{
				x: 54.14,
				y: 18.539999999999992,
				width: 42.75,
				height: 55.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "navigationComputer",
			},
		]
	},
	{
		id: locations.v001,
		version: 3,
		imageURL: viewBackgrounds.v001b,
		return: locations.v002,
		clickableObjects: [
			{
				x: 7.54,
				y: 10.840000000000003,
				width: 12.43,
				height: 37.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "assistantConfused",
			},
			{
				x: 54.14,
				y: 18.539999999999992,
				width: 42.75,
				height: 55.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "navigationComputer",
			},
		]
	},
	{
		id: locations.v001,
		version: 4,
		imageURL: viewBackgrounds.v001b,
		return: locations.v002,
		clickableObjects: [
			{
				x: 7.54,
				y: 10.840000000000003,
				width: 12.43,
				height: 37.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "assistantEvacuations",
			},
			{
				x: 54.14,
				y: 18.539999999999992,
				width: 42.75,
				height: 55.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "navigationComputer",
			},
		]
	},
	{
		id: locations.v002,
		version: 0,
		imageURL: viewBackgrounds.v002,
		return: locations.v001,
		clickableObjects: [
			{
				x: 28.1,
				y: 17.17,
				width: 20.33,
				height: 48.5,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v003,
					},
				]
			},
			{
				x: 54.11,
				y: 18.370000000000005,
				width: 20.33,
				height: 52.69,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v008,
					},
				]
			},
		]
	},
	{
		id: locations.v003,
		version: 0,
		imageURL: viewBackgrounds.v003,
		return: locations.v004,
		clickableObjects: [
			{
				x: 42.63,
				y: 26.179999999999996,
				width: 19.32,
				height: 30.12,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v005,
					},
				]
			},
		]
	},
	{
		id: locations.v004,
		version: 0,
		imageURL: viewBackgrounds.v004,
		return: locations.v003,
		clickableObjects: [
			{
				x: 41.81,
				y: 55.370000000000005,
				width: 17.82,
				height: 22.41,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v008,
					},
				]
			},
			{
				x: 60.92,
				y: 24.709999999999994,
				width: 17.1,
				height: 39.27,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v001,
					},
				]
			},
		]
	},
	{
		id: locations.v005,
		version: 0,
		imageURL: viewBackgrounds.v005,
		return: locations.v006,
		clickableObjects: [
			{
				x: 4.26,
				y: 10.57,
				width: 37.5,
				height: 51.86,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "4",
			},
			{
				x: 55.59,
				y: 9.980000000000004,
				width: 29.71,
				height: 51.86,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "4",
			},
		]
	},
	{
		id: locations.v006,
		version: 0,
		imageURL: viewBackgrounds.v006,
		return: locations.v005,
		clickableObjects: [
			{
				x: 18.33,
				y: 15.030000000000001,
				width: 12.55,
				height: 42.39,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "5",
			},
			{
				x: 35.79,
				y: 15.029999999999994,
				width: 27.85,
				height: 45.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v004,
					},
				]
			},
		]
	},
	{
		id: locations.v007,
		version: 0,
		imageURL: viewBackgrounds.v007,
		return: locations.v008,
		clickableObjects: [
			{
				x: 20.63,
				y: 35.769999999999996,
				width: 13.13,
				height: 22.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v001,
					},
				]
			},
			{
				x: 45.31,
				y: 42.309999999999995,
				width: 17.6,
				height: 25.96,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v003,
					},
				]
			},
		]
	},
	{
		id: locations.v008,
		version: 0,
		imageURL: viewBackgrounds.v008,
		return: locations.v007,
		clickableObjects: [
			{
				x: 22.67,
				y: 40.31,
				width: 17.19,
				height: 50.2,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v009,
					},
				]
			},
		]
	},
	{
		id: locations.v009,
		version: 0,
		imageURL: viewBackgrounds.v009,
		return: locations.v010,
		clickableObjects: [
			{
				x: 13.58,
				y: 54.27999999999999,
				width: 11.82,
				height: 17.51,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "46",
			},
			{
				x: 26.57,
				y: 39.69,
				width: 14.6,
				height: 41.44,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v013,
					},
				]
			},
			{
				x: 47.74,
				y: 39.69,
				width: 14.6,
				height: 41.44,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v011,
					},
				]
			},
		]
	},
	{
		id: locations.v010,
		version: 0,
		imageURL: viewBackgrounds.v010,
		return: locations.v009,
		clickableObjects: [
			{
				x: 53.17,
				y: 39.480000000000004,
				width: 17.23,
				height: 40.28,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v007,
					},
				]
			},
		]
	},
	{
		id: locations.v011,
		version: 0,
		imageURL: viewBackgrounds.v011,
		return: locations.v012,
		clickableObjects: [
			{
				x: 31.1,
				y: 32.53999999999999,
				width: 19.79,
				height: 52.78,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v037,
					},
				]
			},
		]
	},
	{
		id: locations.v012,
		version: 0,
		imageURL: viewBackgrounds.v012,
		return: locations.v011,
		clickableObjects: [
			{
				x: 6.35,
				y: 20.269999999999996,
				width: 16.84,
				height: 77.56,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v010,
					},
				]
			},
			{
				x: 26.29,
				y: 18.310000000000002,
				width: 50.96,
				height: 67.52,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v013,
					},
				]
			},
		]
	},
	{
		id: locations.v013,
		version: 0,
		imageURL: viewBackgrounds.v013,
		return: locations.v014,
		clickableObjects: [
			{
				x: 58.1,
				y: 12.86999999999999,
				width: 25.56,
				height: 68.12,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v015,
					},
				]
			},
		]
	},
	{
		id: locations.v014,
		version: 0,
		imageURL: viewBackgrounds.v014,
		return: locations.v013,
		clickableObjects: [
			{
				x: 28.07,
				y: 24.169999999999995,
				width: 45.47,
				height: 63.35,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v011,
					},
				]
			},
			{
				x: 76.02,
				y: 12.469999999999999,
				width: 20.91,
				height: 85.58,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v010,
					},
				]
			},
		]
	},
	{
		id: locations.v015,
		version: 0,
		imageURL: viewBackgrounds.v015,
		return: locations.v016,
		clickableObjects: [
			{
				x: 20.09,
				y: 29.39999999999999,
				width: 15.14,
				height: 37.2,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "6",
			},
		]
	},
	{
		id: locations.v016,
		version: 0,
		imageURL: viewBackgrounds.v016,
		return: locations.v015,
		clickableObjects: [
			{
				x: 20.51,
				y: 37.83,
				width: 18.85,
				height: 49.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v014,
					},
				]
			},
		]
	},
	{
		id: locations.v017,
		version: 0,
		imageURL: viewBackgrounds.v017,
		return: locations.v018,
		clickableObjects: [
			{
				x: 50.96,
				y: 19.849999999999994,
				width: 24.3,
				height: 60.31,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v019,
					},
				]
			},
		]
	},
	{
		id: locations.v018,
		version: 0,
		imageURL: viewBackgrounds.v018,
		return: locations.v017,
		clickableObjects: [
			{
				x: 34.08,
				y: 15.730000000000004,
				width: 30.94,
				height: 38.65,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "7",
			},
		]
	},
	{
		id: locations.v019,
		version: 0,
		imageURL: viewBackgrounds.v019,
		return: locations.v020,
		clickableObjects: [
			{
				x: 17.81,
				y: 48.199999999999996,
				width: 16.52,
				height: 13.85,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "8",
			},
			{
				x: 37.46,
				y: 35.67,
				width: 18.52,
				height: 25.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v021,
					},
				]
			},
		]
	},
	{
		id: locations.v020,
		version: 0,
		imageURL: viewBackgrounds.v020,
		return: locations.v019,
		clickableObjects: [
			{
				x: 23.88,
				y: 29.43,
				width: 21.15,
				height: 53.65,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v018,
					},
				]
			},
		]
	},
	{
		id: locations.v021,
		version: 0,
		imageURL: viewBackgrounds.v021,
		return: locations.v022,
		clickableObjects: [
			{
				x: 30.69,
				y: 23.93,
				width: 31.57,
				height: 36.27,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v023,
					},
				]
			},
		]
	},
	{
		id: locations.v022,
		version: 0,
		imageURL: viewBackgrounds.v022,
		return: locations.v021,
		clickableObjects: [
			{
				x: 39.65,
				y: 33.730000000000004,
				width: 17.03,
				height: 30.39,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v020,
					},
				]
			},
			{
				x: 71.81,
				y: 20.39,
				width: 28.19,
				height: 46.47,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "8",
			},
		]
	},
	{
		id: locations.v023,
		version: 0,
		imageURL: viewBackgrounds.v023,
		return: locations.v024,
		clickableObjects: [
			{
				x: 16.37,
				y: 17.549999999999997,
				width: 24.27,
				height: 58.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v029,
					},
				]
			},
			{
				x: 55.12,
				y: 19.299999999999997,
				width: 24.27,
				height: 58.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v025,
					},
				]
			},
		]
	},
	{
		id: locations.v024,
		version: 0,
		imageURL: viewBackgrounds.v024,
		return: locations.v023,
		clickableObjects: [
			{
				x: 35.6,
				y: 28.11,
				width: 21.57,
				height: 31.33,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v022,
					},
				]
			},
		]
	},
	{
		id: locations.v025,
		version: 0,
		imageURL: viewBackgrounds.v025,
		return: locations.v026,
		clickableObjects: [
			{
				x: 34.51,
				y: 20.479999999999997,
				width: 35.55,
				height: 42.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "closedCleaning",
			},
		]
	},
	{
		id: locations.v025,
		version: 1,
		imageURL: viewBackgrounds.v025,
		return: locations.v026,
		clickableObjects: [
			{
				x: 34.51,
				y: 20.479999999999997,
				width: 35.55,
				height: 42.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "openDinner",
			},
		]
	},
	{
		id: locations.v025,
		version: 2,
		imageURL: viewBackgrounds.v025,
		return: locations.v026,
		clickableObjects: [
			{
				x: 34.51,
				y: 20.479999999999997,
				width: 35.55,
				height: 42.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "closedAfterDinner",
			},
		]
	},
	{
		id: locations.v026,
		version: 0,
		imageURL: viewBackgrounds.v026,
		return: locations.v025,
		clickableObjects: [
			{
				x: 15.12,
				y: 19.18,
				width: 24.38,
				height: 58.90,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v024,
					},
				]
			},
			{
				x: 45.23,
				y: 60.27,
				width: 37.00,
				height: 39.73,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v029,
					},
				]
			},
		]
	},
	{
		id: locations.v027,
		version: 0,
		imageURL: viewBackgrounds.v027,
		return: locations.v028,
		clickableObjects: [
			{
				x: 26.52,
				y: 7.769999999999996,
				width: 40.43,
				height: 49.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "dinnerPreReceiveP2",
			},
		]
	},
	{
		id: locations.v027,
		version: 1,
		imageURL: viewBackgrounds.v027,
		return: locations.v028,
		clickableObjects: [
			{
				x: 26.52,
				y: 7.769999999999996,
				width: 40.43,
				height: 49.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "dinnerPostReceiveP2",
			},
		]
	},
	{
		id: locations.v028,
		version: 0,
		imageURL: viewBackgrounds.v028,
		return: locations.v027,
		clickableObjects: [
			{
				x: 26.27,
				y: 12.380000000000003,
				width: 36.72,
				height: 43.91,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "12",
			},
		]
	},
	{
		id: locations.v029,
		version: 0,
		imageURL: viewBackgrounds.v029,
		return: locations.v030,
		clickableObjects: [
			{
				x: 14.12,
				y: 13.929999999999993,
				width: 27.65,
				height: 65.29,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v031,
					},
				]
			},
			{
				x: 52.5,
				y: 15.099999999999994,
				width: 27.65,
				height: 65.29,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v033,
					},
				]
			},
		]
	},
	{
		id: locations.v030,
		version: 0,
		imageURL: viewBackgrounds.v030,
		return: locations.v029,
		clickableObjects: [
			{
				x: 36.68,
				y: 40.97,
				width: 23.58,
				height: 36.31,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v025,
					},
				]
			},
			{
				x: 71.91,
				y: 15.920000000000002,
				width: 23.14,
				height: 82.14,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v024,
					},
				]
			},
		]
	},
	{
		id: locations.v031,
		version: 0,
		imageURL: viewBackgrounds.v031,
		return: locations.v032,
		clickableObjects: [
			{
				x: 42.46,
				y: 31.910000000000004,
				width: 15.51,
				height: 19.15,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV83",
			},
		]
	},
	{
		id: locations.v031,
		version: 1,
		imageURL: viewBackgrounds.v031,
		return: locations.v032,
		clickableObjects: [
			{
				x: 42.46,
				y: 31.910000000000004,
				width: 15.51,
				height: 19.15,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorWontOpen",
			},
		]
	},
	{
		id: locations.v031,
		version: 2,
		imageURL: viewBackgrounds.v031,
		return: locations.v032,
		clickableObjects: [
			{
				x: 42.46,
				y: 31.910000000000004,
				width: 15.51,
				height: 19.15,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV83Again",
			},
		]
	},
	{
		id: locations.v032,
		version: 0,
		imageURL: viewBackgrounds.v032,
		return: locations.v031,
		clickableObjects: [
			{
				x: 37.82,
				y: 32.07,
				width: 19.13,
				height: 35.06,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v033,
					},
				]
			},
			{
				x: 65.17,
				y: 17.33,
				width: 14.05,
				height: 75.10,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v030,
					},
				]
			},
		]
	},
	{
		id: locations.v033,
		version: 0,
		imageURL: viewBackgrounds.v033,
		return: locations.v034,
		clickableObjects: [
			{
				x: 36.43,
				y: 29.999999999999996,
				width: 14.69,
				height: 25.8,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "14",
			},
		]
	},
	{
		id: locations.v034,
		version: 0,
		imageURL: viewBackgrounds.v034,
		return: locations.v033,
		clickableObjects: [
			{
				x: 26.31,
				y: 44.379999999999995,
				width: 12.65,
				height: 32.95,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v030,
					},
				]
			},
			{
				x: 39.53,
				y: 41.089999999999996,
				width: 13.37,
				height: 29.65,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v031,
					},
				]
			},
		]
	},
	{
		id: locations.v035,
		version: 0,
		imageURL: viewBackgrounds.v035,
		return: locations.v036,
		clickableObjects: [
			{
				x: 6.07,
				y: 21.5,
				width: 46.6,
				height: 46.94,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "16",
			},
			{
				x: 54.29,
				y: 12.619999999999997,
				width: 12.87,
				height: 20.71,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "15",
			},
		]
	},
	{
		id: locations.v036,
		version: 0,
		imageURL: viewBackgrounds.v036,
		return: locations.v035,
		clickableObjects: [
			{
				x: 31.05,
				y: 9.740000000000002,
				width: 34.25,
				height: 53.14,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "17",
			},
		]
	},
	{
		id: locations.v037,
		version: 0,
		imageURL: viewBackgrounds.v037,
		return: locations.v038,
		clickableObjects: [
			{
				x: 15.74,
				y: 19.610000000000007,
				width: 25.74,
				height: 60.98,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v039,
					},
				]
			},
			{
				x: 57.35,
				y: 19.610000000000007,
				width: 25.74,
				height: 60.98,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v051,
					},
				]
			},
		]
	},
	{
		id: locations.v038,
		version: 0,
		imageURL: viewBackgrounds.v038,
		return: locations.v037,
		clickableObjects: [
			{
				x: 60.52,
				y: 12.290000000000006,
				width: 23.04,
				height: 67.83,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v012,
					},
				]
			},
		]
	},
	{
		id: locations.v039,
		version: 0,
		imageURL: viewBackgrounds.v039,
		return: locations.v040,
		clickableObjects: [
			{
				x: 6.83,
				y: 32.45,
				width: 22.62,
				height: 31.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "18",
			},
			{
				x: 59.46,
				y: 18.97,
				width: 23.76,
				height: 57.31,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v041,
					},
				]
			},
		]
	},
	{
		id: locations.v040,
		version: 0,
		imageURL: viewBackgrounds.v040,
		return: locations.v039,
		clickableObjects: [
			{
				x: 37.55,
				y: 43.18999999999999,
				width: 28.92,
				height: 36.85,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v051,
					},
				]
			},
			{
				x: 70.94,
				y: 29.370000000000005,
				width: 17.7,
				height: 67.56,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v038,
					},
				]
			},
		]
	},
	{
		id: locations.v041,
		version: 0,
		imageURL: viewBackgrounds.v041,
		return: locations.v042,
		clickableObjects: [
			{
				x: 31.92,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v045,
					},
				]
			},
			{
				x: 48.61,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v044,
					},
				]
			},
			{
				x: 58.86,
				y: 20.86,
				width: 21.52,
				height: 44.83,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV87WontOpenInitial",
			},
		]
	},
	{
		id: locations.v041,
		version: 1,
		imageURL: viewBackgrounds.v041,
		return: locations.v042,
		clickableObjects: [
			{
				x: 31.92,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v045,
					},
				]
			},
			{
				x: 48.61,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v044,
					},
				]
			},
			{
				x: 58.86,
				y: 20.86,
				width: 21.52,
				height: 44.83,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV87Open",
			},
		]
	},
	{
		id: locations.v041,
		version: 2,
		imageURL: viewBackgrounds.v041,
		return: locations.v042,
		clickableObjects: [
			{
				x: 31.92,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v045,
					},
				]
			},
			{
				x: 48.61,
				y: 49.7,
				width: 11.71,
				height: 27.49,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v044,
					},
				]
			},
			{
				x: 58.86,
				y: 20.86,
				width: 21.52,
				height: 44.83,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV87WontOpenSecond",
			},
		]
	},
	{
		id: locations.v042,
		version: 0,
		imageURL: viewBackgrounds.v042,
		return: locations.v041,
		clickableObjects: [
			{
				x: 23.61,
				y: 30.53000000000001,
				width: 26.39,
				height: 53.62,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v040,
					},
				]
			},
		]
	},
	{
		id: locations.v043,
		version: 0,
		imageURL: viewBackgrounds.v043,
		return: locations.v044,
		clickableObjects: [
			{
				x: 15.5,
				y: 30.410000000000004,
				width: 15.35,
				height: 60.82,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v042,
					},
				]
			},
			{
				x: 34.5,
				y: 43.66,
				width: 27.78,
				height: 31.97,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v045,
					},
				]
			},
		]
	},
	{
		id: locations.v044,
		version: 0,
		imageURL: viewBackgrounds.v044,
		return: locations.v043,
		clickableObjects: [
			{
				x: 34.78,
				y: 58.08000000000001,
				width: 20.63,
				height: 22.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "20",
			},
			{
				x: 60.17,
				y: 26.349999999999994,
				width: 16.02,
				height: 46.92,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "21",
			},
		]
	},
	{
		id: locations.v045,
		version: 0,
		imageURL: viewBackgrounds.v045,
		return: locations.v046,
		clickableObjects: [
			{
				x: 25.11,
				y: 28.43,
				width: 23.66,
				height: 55.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v047,
					},
				]
			},
		]
	},
	{
		id: locations.v046,
		version: 0,
		imageURL: viewBackgrounds.v046,
		return: locations.v045,
		clickableObjects: [
			{
				x: 38.29,
				y: 40.28,
				width: 27.39,
				height: 35.95,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v044,
					},
				]
			},
			{
				x: 67.6,
				y: 14.939999999999998,
				width: 25.18,
				height: 82.51,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v042,
					},
				]
			},
		]
	},
	{
		id: locations.v047,
		version: 0,
		imageURL: viewBackgrounds.v047,
		return: locations.v048,
		clickableObjects: [
			{
				x: 38.72,
				y: 39.89,
				width: 16.88,
				height: 19.85,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "22",
			},
		]
	},
	{
		id: locations.v048,
		version: 0,
		imageURL: viewBackgrounds.v048,
		return: locations.v047,
		clickableObjects: [
			{
				x: 58.99,
				y: 34.1,
				width: 18.84,
				height: 47.98,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v046,
					},
				]
			},
		]
	},
	{
		id: locations.v049,
		version: 0,
		imageURL: viewBackgrounds.v049,
		return: locations.v050,
		clickableObjects: [
			{
				x: 2.71,
				y: 6.859999999999999,
				width: 21.14,
				height: 45.71,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "23",
			},
			{
				x: 29.0,
				y: 31.05,
				width: 24.14,
				height: 27.24,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "24",
			},
			{
				x: 59.29,
				y: 19.810000000000002,
				width: 26.29,
				height: 47.05,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "25",
			},
		]
	},
	{
		id: locations.v050,
		version: 0,
		imageURL: viewBackgrounds.v050,
		return: locations.v049,
		clickableObjects: [
			{
				x: 28.61,
				y: 16.370000000000005,
				width: 41.18,
				height: 32.76,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "26",
			},
		]
	},
	{
		id: locations.v051,
		version: 0,
		imageURL: viewBackgrounds.v051,
		return: locations.v052,
		clickableObjects: [
			{
				x: 18.71,
				y: 34.9,
				width: 19.27,
				height: 43.71,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v057,
					},
				]
			},
			{
				x: 51.48,
				y: 41.839999999999996,
				width: 17.86,
				height: 34.9,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v054,
					},
				]
			},
		]
	},
	{
		id: locations.v052,
		version: 0,
		imageURL: viewBackgrounds.v052,
		return: locations.v051,
		clickableObjects: [
			{
				x: 20.38,
				y: 31.33,
				width: 26.49,
				height: 61.28,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v038,
					},
				]
			},
			{
				x: 51.09,
				y: 49.809999999999995,
				width: 24.31,
				height: 33.07,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v039,
					},
				]
			},
		]
	},
	{
		id: locations.v053,
		version: 0,
		imageURL: viewBackgrounds.v053,
		return: locations.v054,
		clickableObjects: [
			{
				x: 24.68,
				y: 31.169999999999995,
				width: 15.78,
				height: 52.96,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v052,
					},
				]
			},
			{
				x: 41.89,
				y: 43.22,
				width: 23.67,
				height: 32.5,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v057,
					},
				]
			},
		]
	},
	{
		id: locations.v054,
		version: 0,
		imageURL: viewBackgrounds.v054,
		return: locations.v053,
		clickableObjects: [
			{
				x: 25.0,
				y: 34.72,
				width: 13.84,
				height: 22.82,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "27",
			},
			{
				x: 67.26,
				y: 35.32,
				width: 10.86,
				height: 27.78,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "28",
			},
		]
	},
	{
		id: locations.v055,
		version: 0,
		imageURL: viewBackgrounds.v055,
		return: locations.v056,
		clickableObjects: [
			{
				x: 27.64,
				y: 23.549999999999997,
				width: 17.51,
				height: 39,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "29",
			},
		]
	},
	{
		id: locations.v056,
		version: 0,
		imageURL: viewBackgrounds.v056,
		return: locations.v055,
		clickableObjects: [
			{
				x: 31.69,
				y: 8.68,
				width: 31.26,
				height: 45.95,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "30",
			},
		]
	},
	{
		id: locations.v057,
		version: 0,
		imageURL: viewBackgrounds.v057,
		return: locations.v058,
		clickableObjects: [
			{
				x: 21.12,
				y: 35.44,
				width: 30.09,
				height: 44.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v059,
					},
				]
			},
			{
				x: 57.9,
				y: 36.22,
				width: 20.09,
				height: 44.09,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v061,
					},
				]
			},
		]
	},
	{
		id: locations.v058,
		version: 0,
		imageURL: viewBackgrounds.v058,
		return: locations.v057,
		clickableObjects: [
			{
				x: 38.36,
				y: 42.72,
				width: 24.28,
				height: 31.23,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v054,
					},
				]
			},
			{
				x: 73.28,
				y: 17.629999999999995,
				width: 20.26,
				height: 81.03,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v052,
					},
				]
			},
		]
	},
	{
		id: locations.v059,
		version: 0,
		imageURL: viewBackgrounds.v059,
		return: locations.v060,
		clickableObjects: [
			{
				x: 37.61,
				y: 28.349999999999998,
				width: 20.65,
				height: 24.41,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV85WontOpenInitial",
			},
		]
	},
	{
		id: locations.v059,
		version: 1,
		imageURL: viewBackgrounds.v059,
		return: locations.v060,
		clickableObjects: [
			{
				x: 37.61,
				y: 28.349999999999998,
				width: 20.65,
				height: 24.41,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV85Open",
			},
		]
	},
	{
		id: locations.v059,
		version: 2,
		imageURL: viewBackgrounds.v059,
		return: locations.v060,
		clickableObjects: [
			{
				x: 37.61,
				y: 28.349999999999998,
				width: 20.65,
				height: 24.41,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorToV85WontOpenSecond",
			},
		]
	},
	{
		id: locations.v060,
		version: 0,
		imageURL: viewBackgrounds.v060,
		return: locations.v059,
		clickableObjects: [
			{
				x: 39.02,
				y: 42.57000000000001,
				width: 25.07,
				height: 25.94,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v061,
					},
				]
			},
			{
				x: 75.82,
				y: 8.519999999999996,
				width: 20.62,
				height: 86.53,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v058,
					},
				]
			},
		]
	},
	{
		id: locations.v061,
		version: 0,
		imageURL: viewBackgrounds.v061,
		return: locations.v062,
		clickableObjects: [
			{
				x: 31.66,
				y: 22.94,
				width: 39.76,
				height: 28.24,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "v61OpenDoorToV63",
			},
		]
	},
	{
		id: locations.v062,
		version: 0,
		imageURL: viewBackgrounds.v062,
		return: locations.v061,
		clickableObjects: [
			{
				x: 7.64,
				y: 17.22,
				width: 22.76,
				height: 76.91,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v058,
					},
				]
			},
			{
				x: 36.27,
				y: 42.47,
				width: 24.38,
				height: 26.22,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v059,
					},
				]
			},
		]
	},
	{
		id: locations.v063,
		version: 0,
		imageURL: viewBackgrounds.v063,
		return: locations.v064,
		clickableObjects: [
			{
				x: 34.25,
				y: 45.06999999999999,
				width: 37.59,
				height: 29.98,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "32",
			},
			{
				x: 80.55,
				y: 40.809999999999995,
				width: 17.85,
				height: 29.21,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "33",
			},
		]
	},
	{
		id: locations.v064,
		version: 0,
		imageURL: viewBackgrounds.v064,
		return: locations.v063,
		clickableObjects: [
			{
				x: 23.26,
				y: 24.11,
				width: 42.67,
				height: 32.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "34",
			},
		]
	},
	{
		id: locations.v065,
		version: 0,
		imageURL: viewBackgrounds.v065,
		return: locations.v066,
		clickableObjects: [
			{
				x: 6.99,
				y: 13.97999999999999,
				width: 36.1,
				height: 68.54,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "35",
			},
			{
				x: 45.85,
				y: 51.27000000000001,
				width: 14.7,
				height: 23.88,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v067,
					},
				]
			},
			{
				x: 61.86,
				y: 12.420000000000009,
				width: 21.54,
				height: 53.79,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "35",
			},
		]
	},
	{
		id: locations.v066,
		version: 0,
		imageURL: viewBackgrounds.v066,
		return: locations.v065,
		clickableObjects: [
			{
				x: 32.75,
				y: 15.659999999999997,
				width: 32.31,
				height: 37.57,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "backUpToJESO",
			},
		]
	},
	{
		id: locations.v066,
		version: 1,
		imageURL: viewBackgrounds.v066,
		return: locations.v065,
		clickableObjects: [
			{
				x: 32.75,
				y: 15.659999999999997,
				width: 32.31,
				height: 37.57,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "backUpToEmptyElevator",
			},
		]
	},
	{
		id: locations.v066,
		version: 2,
		imageURL: viewBackgrounds.v066,
		return: locations.v065,
		clickableObjects: [
			{
				x: 32.75,
				y: 15.659999999999997,
				width: 32.31,
				height: 37.57,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "backUpToAgentFinal",
			},
		]
	},
	{
		id: locations.v067,
		version: 0,
		imageURL: viewBackgrounds.v067,
		return: locations.v068,
		clickableObjects: [
			{
				x: 21.49,
				y: 37.19,
				width: 14.94,
				height: 44.72,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v070,
					},
				]
			},
			{
				x: 63.11,
				y: 37.800000000000004,
				width: 14.18,
				height: 42.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v071,
					},
				]
			},
		]
	},
	{
		id: locations.v068,
		version: 0,
		imageURL: viewBackgrounds.v068,
		return: locations.v067,
		clickableObjects: [
			{
				x: 19.94,
				y: 30.160000000000004,
				width: 23.36,
				height: 33.93,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "35",
			},
			{
				x: 43.15,
				y: 47.02,
				width: 14.29,
				height: 18.85,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v066,
					},
				]
			},
			{
				x: 57.89,
				y: 14.479999999999997,
				width: 23.66,
				height: 54.96,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "35",
			},
		]
	},
	{
		id: locations.v069,
		version: 0,
		imageURL: viewBackgrounds.v069,
		return: locations.v070,
		clickableObjects: [
			{
				x: 35.57,
				y: 42.260000000000005,
				width: 26.04,
				height: 28.77,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v071,
					},
				]
			},
			{
				x: 65.03,
				y: 28.769999999999996,
				width: 14.88,
				height: 43.25,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowRight,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v068,
					},
				]
			},
		]
	},
	{
		id: locations.v070,
		version: 0,
		imageURL: viewBackgrounds.v070,
		return: locations.v069,
	},
	{
		id: locations.v071,
		version: 0,
		imageURL: viewBackgrounds.v071,
		return: locations.v072,
		clickableObjects: [
			{
				x: 18.17,
				y: 20.150000000000002,
				width: 17.01,
				height: 24.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyRequestsP3",
			},
			{
				x: 36.63,
				y: 38.18,
				width: 25.73,
				height: 28.68,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyRequestsP3",
			},
		]
	},
	{
		id: locations.v071,
		version: 1,
		imageURL: viewBackgrounds.v071,
		return: locations.v072,
		clickableObjects: [
			{
				x: 18.17,
				y: 20.150000000000002,
				width: 17.01,
				height: 24.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyReceivesP3AndEnterTogether",
			},
			{
				x: 36.63,
				y: 38.18,
				width: 25.73,
				height: 28.68,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyReceivesP3AndEnterTogether",
			},
		]
	},
	{
		id: locations.v071,
		version: 2,
		imageURL: viewBackgrounds.v071,
		return: locations.v072,
		clickableObjects: [
			{
				x: 18.17,
				y: 20.150000000000002,
				width: 17.01,
				height: 24.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyleakIsFixedAndEnterTogether",
			},
			{
				x: 36.63,
				y: 38.18,
				width: 25.73,
				height: 28.68,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "garyleakIsFixedAndEnterTogether",
			},
		]
	},
	{
		id: locations.v072,
		version: 0,
		imageURL: viewBackgrounds.v072,
		return: locations.v071,
		clickableObjects: [
			{
				x: 15.04,
				y: 26.39,
				width: 18.91,
				height: 54.3,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowLeft,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v068,
					},
				]
			},
			{
				x: 38.25,
				y: 42.44999999999999,
				width: 24.5,
				height: 24.28,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v070,
					},
				]
			},
		]
	},
	{
		id: locations.v073,
		version: 0,
		imageURL: viewBackgrounds.v073,
		return: locations.v074,
		clickableObjects: [
			{
				x: 29.88,
				y: 39.63999999999999,
				width: 12.35,
				height: 38.21,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v075,
					},
				]
			},
			{
				x: 49.24,
				y: 49.79,
				width: 12.96,
				height: 27.85,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v079,
					},
				]
			},
			{
				x: 66.31,
				y: 32.72,
				width: 10.37,
				height: 44.31,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v081,
					},
				]
			},
		]
	},
	{
		id: locations.v074,
		version: 0,
		imageURL: viewBackgrounds.v074,
		return: locations.v073,
		clickableObjects: [
			{
				x: 38.58,
				y: 29.509999999999998,
				width: 28.04,
				height: 30.89,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "39",
			},
		]
	},
	{
		id: locations.v075,
		version: 0,
		imageURL: viewBackgrounds.v075,
		return: locations.v076,
		clickableObjects: [
			{
				x: 44.23,
				y: 48.32000000000001,
				width: 10.36,
				height: 16.77,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "40",
			},
		]
	},
	{
		id: locations.v076,
		version: 0,
		imageURL: viewBackgrounds.v076,
		return: locations.v075,
		clickableObjects: [
			{
				x: 40.45,
				y: 41.03000000000001,
				width: 18.51,
				height: 25.9,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v074,
					},
				]
			},
		]
	},
	{
		id: locations.v077,
		version: 0,
		imageURL: viewBackgrounds.v077,
		return: locations.v078,
		clickableObjects: [
			{
				x: 38.81,
				y: 28.289999999999996,
				width: 17.01,
				height: 22.87,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "49",
			},
		]
	},
	{
		id: locations.v078,
		version: 0,
		imageURL: viewBackgrounds.v078,
		return: locations.v077,
		clickableObjects: [
			{
				x: 31.99,
				y: 11.11,
				width: 24.11,
				height: 35.52,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "outOfFergusonIntoV76",
			},
		]
	},
	{
		id: locations.v079,
		version: 0,
		imageURL: viewBackgrounds.v079,
		return: locations.v080,
		clickableObjects: [
			{
				x: 34.04,
				y: 30.52,
				width: 33.43,
				height: 27.31,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "48",
			},
		]
	},
	{
		id: locations.v080,
		version: 0,
		imageURL: viewBackgrounds.v080,
		return: locations.v079,
		clickableObjects: [
			{
				x: 35.86,
				y: 25.709999999999994,
				width: 27.86,
				height: 50.86,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v074,
					},
				]
			},
		]
	},
	{
		id: locations.v081,
		version: 0,
		imageURL: viewBackgrounds.v081,
		return: locations.v082,
		clickableObjects: [
			{
				x: 49.86,
				y: 47.0,
				width: 9.57,
				height: 14.7,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "47",
			},
		]
	},
	{
		id: locations.v082,
		version: 0,
		imageURL: viewBackgrounds.v082,
		return: locations.v081,
		clickableObjects: [
			{
				x: 41.11,
				y: 47.86000000000001,
				width: 15.74,
				height: 20.82,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.v074,
					},
				]
			},
		]
	},
	{
		id: locations.v083,
		version: 0,
		imageURL: viewBackgrounds.v083,
		return: locations.v084,
		clickableObjects: [
			{
				x: 31.54,
				y: 10.270000000000003,
				width: 33.14,
				height: 38.57,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "butlerAskForPermit1",
			},
		]
	},
	{
		id: locations.v083,
		version: 1,
		imageURL: viewBackgrounds.v083,
		return: locations.v084,
		clickableObjects: [
			{
				x: 31.54,
				y: 10.270000000000003,
				width: 33.14,
				height: 38.57,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "butlerTellDogStory",
			},
		]
	},
	{
		id: locations.v084,
		version: 0,
		imageURL: viewBackgrounds.v084,
		return: locations.v083,
		clickableObjects: [
			{
				x: 38.27,
				y: 15.620000000000005,
				width: 22.14,
				height: 33.4,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV32",
			},
		]
	},
	{
		id: locations.v085,
		version: 0,
		imageURL: viewBackgrounds.v085,
		return: locations.v086,
		clickableObjects: [
			{
				x: 26.62,
				y: 13.829999999999998,
				width: 35.19,
				height: 42.28,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "jesoTravelToBasement",
			},
		]
	},
	{
		id: locations.v085,
		version: 1,
		imageURL: viewBackgrounds.v085,
		return: locations.v086,
		clickableObjects: [
			{
				x: 26.62,
				y: 13.829999999999998,
				width: 35.19,
				height: 42.28,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "jesoNotSupposedToBeHere",
			},
		]
	},
	{
		id: locations.v086,
		version: 0,
		imageURL: viewBackgrounds.v086,
		return: locations.v085,
		clickableObjects: [
			{
				x: 39.04,
				y: 26.96,
				width: 27.31,
				height: 37.65,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV60",
			},
		]
	},
	{
		id: locations.v087,
		version: 0,
		imageURL: viewBackgrounds.v087,
		return: locations.v088,
		clickableObjects: [
			{
				x: 21.91,
				y: 7.399999999999999,
				width: 44.38,
				height: 42.13,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "agentAskForP2",
			},
		]
	},
	{
		id: locations.v087,
		version: 1,
		imageURL: viewBackgrounds.v087,
		return: locations.v088,
		clickableObjects: [
			{
				x: 21.91,
				y: 7.399999999999999,
				width: 44.38,
				height: 42.13,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "agentTravelToSpecial",
			},
		]
	},
	{
		id: locations.v088,
		version: 0,
		imageURL: viewBackgrounds.v088,
		return: locations.v087,
		clickableObjects: [
			{
				x: 37.97,
				y: 13.549999999999997,
				width: 25.11,
				height: 32.07,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV42",
			},
		]
	},
	{
		id: locations.v089,
		version: 0,
		imageURL: viewBackgrounds.v089,
		return: locations.v090,
		clickableObjects: [
			{
				x: 21.91,
				y: 7.399999999999999,
				width: 44.38,
				height: 42.13,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "outOfOperationEmergencyProtocol",
			},
		]
	},
	{
		id: locations.v089,
		version: 1,
		imageURL: viewBackgrounds.v089,
		return: locations.v090,
	},
	{
		id: locations.v090,
		version: 0,
		imageURL: viewBackgrounds.v090,
		return: locations.v089,
		clickableObjects: [
			{
				x: 37.25,
				y: 18.36,
				width: 24.64,
				height: 32.5,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "doorIntoV53",
			},
		]
	},
	{
		id: locations.b01,
		version: 0,
		imageURL: viewBackgrounds.B01,
		return: locations.v071,
		clickableObjects: [
			{
				x: 2.56,
				y: 17.07,
				width: 17.35,
				height: 25.81,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "clickLeakPreFix",
			},
			{
				x: 29.16,
				y: 4.93,
				width: 13.66,
				height: 38.14,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "clickGaryInB01preFix",
			},
		]
	},
	{
		id: locations.b01,
		version: 1,
		imageURL: viewBackgrounds.B01b,
		return: locations.v071,
		onEntryId: "fixLeakTransition",
		clickableObjects: [
			{
				x: 29.16,
				y: 4.93,
				width: 13.66,
				height: 38.14,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "clickGaryInB01postFix",
			},
		]
	},
	{
		id: locations.vBUTTONS,
		version: 0,
		imageURL: viewBackgrounds.vBUTTONS,
		return: locations.v087,
		clickableObjects: [
			{
				x: 17.68,
				y: 67.95,
				width: 10.43,
				height: 13.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "toDeck",
			},
			{
				x: 17.97,
				y: 52.90,
				width: 10.29,
				height: 13.32,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "toSpecial",
			},
			{
				x: 17.54,
				y: 37.64,
				width: 10.29,
				height: 11.97,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "toMainAlreadyThere",
			},
			{
				x: 17.83,
				y: 20.85,
				width: 10.43,
				height: 14.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.hoverAction,
				reactionsId: "toBasement",
			},
		]
	},
	{
		id: locations.v087,
		version: 2,
		imageURL: viewBackgrounds.v087b,
		return: locations.v088,
		clickableObjects: [
			{
				x: 33.33,
				y: 18.720000000000006,
				width: 35.8,
				height: 38.48,
				imageURL: imageURLs.transparent,
				hoverImage: hoverImages.arrowUp,
				reactions: [
					{
						type: reactionTypes.navigate,
						content: locations.vBUTTONS,
					},
				]
			},
		]
	},
	{
		id: locations.deck,
		version: 0,
		imageURL: viewBackgrounds.S,
		return: locations.v087,
	},
]