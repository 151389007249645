import useContentSize from './useContentSize'
import ClickableObject from './ClickableOject'

const GameBackground = props => {
	const { currentNode, guiRatio, react, dialogInProgress, scale, left, top } = props

	const backgroundDimension = useContentSize(currentNode.imageURL ? currentNode.imageURL : null)

	const contentRatio = backgroundDimension.h / backgroundDimension.w
	const hasImage = currentNode && currentNode.imageURL

	const gamebackground = hasImage ? {
		width: `${scale}%`,
		height: `calc(${scale}% * ${contentRatio} / ${guiRatio})`,
		position: "absolute",
		left: `${left}%`,
		top: `${top}%`,
		backgroundImage: `url("${currentNode.imageURL}")`,
		backgroundSize: "100%",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat"
	} : {}

	return hasImage ? <div style={gamebackground}>
		{currentNode.clickableObjects && currentNode.clickableObjects
			.map((co, index) =>
				<ClickableObject
					key={`${index}-${co}`}
					backgroundRatio={contentRatio}
					react={react}
					clickable={co.reactions || co.reactionsId}
					interrupted={dialogInProgress}
					{...co}
				/>
			)}
	</div> : <></>
}

export default GameBackground