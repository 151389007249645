import { useEffect, useState } from 'react'

function useContentSize(nextBackground) {
	const [size, setSize] = useState({ w: 1, h: 1 })

	useEffect(() => {
		function updateSize() {
			setSize({ w: this.naturalWidth, h: this.naturalHeight })
		}

		var img = new Image()
		if (nextBackground) {
			img.addEventListener("load", updateSize)

			if (typeof nextBackground === 'string')
				img.src = nextBackground
			else
				img.src = URL.createObjectURL(nextBackground)
		}


		return () => { nextBackground && img.removeEventListener("load", updateSize) }

	}, [nextBackground])

	return size
}

export default useContentSize