import { useState } from 'react'

import MenuButtonBase from './MenuButtonBase'
import { reactionTypes } from './map'

const InGameButton = props => {
	const {
		backgroundRatio,
		guiTallerThanScreen,
		height,
		width,
		x,
		y,
		label,
		fontSize,
		reactions,
		react,
		interrupted,
		post
	} = props

	const [internalProgress, setIsInProgress] = useState(false)

	const isInProgress = internalProgress || interrupted

	const reactOnAll = async () => {

		if (!isInProgress) {
			if (reactions && react) {

				setIsInProgress(true)
				var hasEnded = false
				for (const item of reactions) {
					if (item && item.type && post) {
						item.override = true
					}

					if (item && item.type && item.type === reactionTypes.endDialog) {
						hasEnded = true
					}

					await react(item)
				}

				!hasEnded && setIsInProgress(false) // This was issue causing a warning. Calling this after a dialogue ends will have removed the item from the canvas.
			}
		}
	}

	return <MenuButtonBase
		backgroundRatio={backgroundRatio}
		guiTallerThanScreen={guiTallerThanScreen}
		height={height}
		width={width}
		x={x}
		y={y}
		label={label}
		fontSize={fontSize}
		onClick={e => {
			e.stopPropagation()
			reactOnAll()
		}}
	/>
}

export default InGameButton