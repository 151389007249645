
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import InMenusButton from "./InMenusButton"
import MenuLabel from "./MenuLabel"

const Menu = props => {
	const { guiRatio, guiTallerThanScreen, fadeGameStart } = props

	const history = useHistory()

	const playGame = () => {

		fadeGameStart(true)

		setTimeout(() => {
			fadeGameStart(false)
			history.push('/start/')
			// }, 2000);
		}, 12000); // TODO Move number elsewhere.
	}

	const base = {
		backgroundRatio: guiRatio,
		guiTallerThanScreen: guiTallerThanScreen,
		width: 35,
		x: 3,
	}

	return <>
		<MenuLabel {...base}
			height={10}
			y={20}
			label={"The Leak"}
		/>
		<InMenusButton {...base}
			height={10}
			y={5}
			label={"Play Game"}
			fontSize={4}
			onClick={playGame}
		/>
		<InMenusButton
			backgroundRatio={guiRatio}
			guiTallerThanScreen={guiTallerThanScreen}
			height={5}
			width={20}
			x={75}
			y={2.5}
			label={'Credits'}
			fontSize={3.5}
			onClick={() => history.push('/attribution')}
		/>
	</>
}

export default Menu