

function getStyle(backgroundRatio, guiTallerThanScreen, height, width, x, y) {
	const baseBorderwidth = 1

	const borderWidth = {
		neutral: guiTallerThanScreen ? `${baseBorderwidth}vh` : `${baseBorderwidth * backgroundRatio}vw`,
		other: guiTallerThanScreen ? `${1.5 * baseBorderwidth}vh` : `${1.5 * baseBorderwidth * backgroundRatio}vw`,
	}

	const all = {
		position: "absolute",

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		userSelect: 'none',
		WebkitUserSelect: 'none',

		outline: "none",
		borderStyle: 'outset',
		borderWidth: borderWidth.neutral,
		borderColor: 'orange',
		backgroundColor: 'yellow',
	}

	const positioning = {

		height: `${height}%`,
		width: width ? `${width}%` : `50%`,

		left: x ? `${x}%` : `25%`,
		right: x ? `${100 - x}%` : '25%',

		top: "",
		bottom: `${y}%`,
	}

	const font = {
		size: 5,
	}

	const textFormatting = {
		fontFamily: "'Press Start 2P'",
		fontSize: guiTallerThanScreen ? `${font.size}vh` : `${font.size * backgroundRatio}vw`,
		color: 'red',
	}

	return { ...all, ...positioning, ...textFormatting }
}


const MenuLabel = props => {
	const { backgroundRatio, guiTallerThanScreen, height, width, y, x, label } = props

	return <div
		style={getStyle(backgroundRatio, guiTallerThanScreen, height, width, x, y)}
		tabIndex={-1}
	>
		{label}
	</div>
}

export default MenuLabel