import { useState } from 'react'
import { states } from './ClickableOject'

function getStyle(backgroundRatio, guiTallerThanScreen, height, width, x, y, state, fontSize) {

	const baseBorderwidth = 1

	const borderWidth = {
		neutral: guiTallerThanScreen ? `${baseBorderwidth}vh` : `${baseBorderwidth * backgroundRatio}vw`,
		other: guiTallerThanScreen ? `${1.5 * baseBorderwidth}vh` : `${1.5 * baseBorderwidth * backgroundRatio}vw`,
	}

	const all = {
		position: "absolute",
		cursor: "pointer",

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		outline: "none",
		borderStyle: state !== states.mouseDown ? 'outset' : 'inset',
		borderWidth: borderWidth.neutral,
		borderColor: state === states.hover ? 'white' : state === states.mouseDown ? 'white' : 'black',
		backgroundColor: 'grey',
		userSelect: 'none',
		WebkitUserSelect: 'none',
	}

	const positioning = {

		height: `${height}%`,
		width: `${width}%`,
		left: `${x}%`,

		top: "",
		bottom: `${y}%`,
	}

	const textFormatting = {
		fontFamily: "'Press Start 2P'",
		fontSize: guiTallerThanScreen ? `${fontSize}vh` : `${fontSize * backgroundRatio}vw`,
		color: state === states.neutral ? 'white' : state === states.mouseDown ? 'black' : 'white',
	}

	return { ...all, ...positioning, ...textFormatting }
}

const MenuButtonBase = props => {
	const { backgroundRatio, guiTallerThanScreen, height, width, x, y, label, fontSize, onClick } = props

	const [hover, setHover] = useState(false)
	const [mouseDown, setMouseDown] = useState(false)

	return <div
		style={getStyle(backgroundRatio, guiTallerThanScreen, height, width, x, y, mouseDown ? states.mouseDown : hover ? states.hover : states.neutral, fontSize)}
		tabIndex={-1}
		onClick={onClick}
		onMouseEnter={() => setHover(true)}
		onMouseLeave={() => {
			setHover(false)
			setMouseDown(false)
		}}
		onMouseDown={() => setMouseDown(true)}
		onMouseUp={() => setMouseDown(false)}
	>
		{label}
	</div>
}

export default MenuButtonBase