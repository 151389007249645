import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import InMenusButton from "./InMenusButton"

const AttributionPage = props => {
	const { guiRatio, guiTallerThanScreen } = props
	const [attributions, setAttributions] = useState("")

	const history = useHistory()

	useEffect(() => {
		fetch('/attribution.txt')
			.then(response => response.text())
			.then((data) => {
				setAttributions(data)
			})
	}, [])

	const [intattributions, setIntattributions] = useState("")

	useEffect(() => {
		fetch('/ourattribution.txt')
			.then(response => response.text())
			.then((data) => {
				setIntattributions(data)
			})
	}, [])

	const base = {
		width: '70%',
		height: '35%',
		overflow: 'scroll',
		backgroundColor: 'white',
		whiteSpace: 'pre-wrap',
		position: "absolute",
		left: '20%',
		fontSize: 'small',
		paddingTop: '2%',
		paddingLeft: '2%',
		paddingBottom: '2%',
		paddingRight: '2%',
		fontFamily: "'Press Start 2P'",
		userSelect: 'none',
		WebkitUserSelect: 'none',
	}

	return <>
		<div style={{
			...base,
			top: '5%',
		}}>
			{intattributions}
		</div>
		<div style={{
			...base,
			top: '50%',
		}}>
			{attributions}
		</div>
		<InMenusButton
			backgroundRatio={guiRatio}
			guiTallerThanScreen={guiTallerThanScreen}
			width={14}
			x={2.5}
			height={10}
			y={9}
			label={"Back"}
			fontSize={4}
			onClick={() => history.push('/')}
		/>
	</>
}

export default AttributionPage