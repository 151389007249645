export const reactionTypes = {
	navigate: "navigate", // content: Stirng: nodeId
	navigateVersion: "navigateVersion", // content: { String: node, Int: version}
	sound: "sound", // content: String: soundUrl
	startBackgroundTrack: "startBackgroundTrack", // content: String: backgroundTrackURL
	delay: "delay", // content: Int: delayInMilliseconds
	runOnce: "runOnce", // content: { String: Id, reaction: { String: type, content: JSON }}
	startDialog: "startDialog", // content: { String: name, String: avatar, [String]: text, postActions: [reactions] } //
	endDialog: "endDialog", // Ends the dialog, content doesnt matter.
	gainProgressItem: "gainProgressItem", // content: String: Id
	// appearObject: "appearObject",
	openMenu: "openMenu",
	changeViewIndex: "changeViewIndex", // content: { string: viewID, int: version }
	fadeOut: "fadeOut", // content: Doesnt matter
}

export const returnActions = {
	turnAround: "turnAround",
	backOut: "backOut",
}

export const navigationDirection = {
	previous: "PREVIOUS_NODE",
}

export const sounds = {
	themesong: "/sounds/theme.mp3",
	leak: "/sounds/leak.mp3",
	click: "/sounds/click.mp3",
}

export const talkSounds = {
	talk1: "/sounds/talk1.mp3",
	talk2: "/sounds/talk2.mp3",
	talk3: "/sounds/talk3.mp3",
	talk4: "/sounds/talk4.mp3",
}

export async function cacheFirst(url) {
	const cache = await caches.open('lbd-theleakgame-v1');
	const cachedResponse = await cache.match(url);

	if (cachedResponse) {
		const cachedBlob = await cachedResponse.blob();
		return cachedBlob;
	}

	const res = await fetch(url);

	if (res.status === 206) {
		const partialRes = await res.blob();
		return partialRes;
	} else {
		await cache.put(url, res.clone());
		return res.blob();
	}
}



export const imageURLs = {
	gui: "/images/backgrounds/leak_gui.png",
	//theleak: "/images/backgrounds/theleak.png",
	menu_background: "/images/backgrounds/menu_screen_background.png",
	transparent: "/images/transparent.png",
	// transparent: "/images/transparent-purple.png",
	dog: "/images/dog.png",
	black: "/images/black.png",
}

export const hoverImages = {
	hoverAction: "/images/hoverImages/hoverAction.png",
	arrowLeft: "/images/hoverImages/arrowLeft.png",
	arrowNE: "/images/hoverImages/arrowNE.png",
	arrowNW: "/images/hoverImages/arrowNW.png",
	arrowRight: "/images/hoverImages/arrowRight.png",
	arrowUp: "/images/hoverImages/arrowUp.png",
}

export const characters = {
	assistant: {
		name: "ASSISTANT",
		avatar: "/images/avatars/assistant.png"
	},
	sanders: {
		name: "CPT SANDERS",
		avatar: "/images/avatars/sanders.png"
	},
	patient: {
		name: "PATIENT",
		// avatar: "/images/avatars/patient.png"
		avatar: "/images/avatars/assistant.png" // TODO
	},
	guest: {
		name: "GUEST",
		//avatar: "/images/avatars/guest.png"
		avatar: "/images/avatars/assistant.png" // TODO
	},
	gary: {
		name: "GARY",
		avatar: "/images/avatars/gary.png"
	},
	butler: {
		name: "BUTLER",
		avatar: "/images/avatars/butler.png"
	},
	jeso: {
		name: "J.E.S.O.",
		avatar: "/images/avatars/jeso.png"
	},
	agent: {
		name: "AGENT",
		avatar: "/images/avatars/agent.png"
	},
	ferguson: {
		name: "FERGUSON",
		avatar: "/images/avatars/ferguson.png"
	},
	empty: {
		name: "",
		avatar: imageURLs.transparent
	}
}

export const viewBackgrounds = {
	v001: "/images/views/001.png",
	v001b: "/images/views/001b.png",
	v002: "/images/views/002.png",
	v003: "/images/views/003.png",
	v004: "/images/views/004.png",
	v005: "/images/views/005.png",
	v006: "/images/views/006.png",
	v007: "/images/views/007.png",
	v008: "/images/views/008.png",
	v009: "/images/views/009.png",
	v010: "/images/views/010.png",
	v011: "/images/views/011.png",
	v012: "/images/views/012.png",
	v013: "/images/views/013.png",
	v014: "/images/views/014.png",
	v015: "/images/views/015.png",
	v016: "/images/views/016.png",
	v017: "/images/views/017.png",
	v018: "/images/views/018.png",
	v019: "/images/views/019.png",
	v020: "/images/views/020.png",
	v021: "/images/views/021.png",
	v022: "/images/views/022.png",
	v023: "/images/views/023.png",
	v024: "/images/views/024.png",
	v025: "/images/views/025.png",
	v026: "/images/views/026.png",
	v027: "/images/views/027.png",
	v028: "/images/views/028.png",
	v029: "/images/views/029.png",
	v030: "/images/views/030.png",
	v031: "/images/views/031.png",
	v032: "/images/views/032.png",
	v033: "/images/views/033.png",
	v034: "/images/views/034.png",
	v035: "/images/views/035.png",
	v036: "/images/views/036.png",
	v037: "/images/views/037.png",
	v038: "/images/views/038.png",
	v039: "/images/views/039.png",
	v040: "/images/views/040.png",
	v041: "/images/views/041.png",
	v042: "/images/views/042.png",
	v043: "/images/views/043.png",
	v044: "/images/views/044.png",
	v045: "/images/views/045.png",
	v046: "/images/views/046.png",
	v047: "/images/views/047.png",
	v048: "/images/views/048.png",
	v049: "/images/views/049.png",
	v050: "/images/views/050.png",
	v051: "/images/views/051.png",
	v052: "/images/views/052.png",
	v053: "/images/views/053.png",
	v054: "/images/views/054.png",
	v055: "/images/views/055.png",
	v056: "/images/views/056.png",
	v057: "/images/views/057.png",
	v058: "/images/views/058.png",
	v059: "/images/views/059.png",
	v060: "/images/views/060.png",
	v061: "/images/views/061.png",
	v062: "/images/views/062.png",
	v063: "/images/views/063.png",
	v064: "/images/views/064.png",
	v065: "/images/views/065.png",
	v066: "/images/views/066.png",
	v067: "/images/views/067.png",
	v068: "/images/views/068.png",
	v069: "/images/views/069.png",
	v070: "/images/views/070.png",
	v071: "/images/views/071.png",
	v072: "/images/views/072.png",
	v073: "/images/views/073.png",
	v074: "/images/views/074.png",
	v075: "/images/views/075.png",
	v076: "/images/views/076.png",

	v077: "/images/views/077.png",
	v078: "/images/views/078.png",
	v079: "/images/views/079.png",
	v080: "/images/views/080.png",
	v081: "/images/views/081.png",
	v082: "/images/views/082.png",
	v083: "/images/views/083.png",
	v084: "/images/views/084.png",
	v085: "/images/views/085.png",
	v086: "/images/views/086.png",
	v087: "/images/views/087.png",
	v087b: "/images/views/087b.png",

	v088: "/images/views/088.png",
	v089: "/images/views/089.png",
	v090: "/images/views/090.png",

	S: "/images/views/S.png",

	B01: "/images/views/B01.png",
	B01b: "/images/views/B01b.png",

	vBUTTONS: "/images/views/BUTTONS.png",

}

export const locations = {
	v001: "001",
	v002: "002",
	v003: "003",
	v004: "004",
	v005: "005",
	v006: "006",
	v007: "007",
	v008: "008",
	v009: "009",
	v010: "010",
	v011: "011",
	v012: "012",
	v013: "013",
	v014: "014",
	v015: "015",
	v016: "016",
	v017: "017",
	v018: "018",
	v019: "019",
	v020: "020",
	v021: "021",
	v022: "022",
	v023: "023",
	v024: "024",
	v025: "025",
	v026: "026",
	v027: "027",
	v028: "028",
	v029: "029",
	v030: "030",
	v031: "031",
	v032: "032",
	v033: "033",
	v034: "034",
	v035: "035",
	v036: "036",
	v037: "037",
	v038: "038",
	v039: "039",
	v040: "040",
	v041: "041",
	v042: "042",
	v043: "043",
	v044: "044",
	v045: "045",
	v046: "046",
	v047: "047",
	v048: "048",
	v049: "049",
	v050: "050",
	v051: "051",
	v052: "052",
	v053: "053",
	v054: "054",
	v055: "055",
	v056: "056",
	v057: "057",
	v058: "058",
	v059: "059",
	v060: "060",
	v061: "061",
	v062: "062",
	v063: "063",
	v064: "064",
	v065: "065",
	v066: "066",
	v067: "067",
	v068: "068",
	v069: "069",
	v070: "070",
	v071: "071",
	v072: "072",
	v073: "073",
	v074: "074",
	v075: "075",
	v076: "076",
	v077: "077",
	v078: "078",
	v079: "079",
	v080: "080",
	v081: "081",
	v082: "082",
	v083: "083",
	v084: "084",
	v085: "085",
	v086: "086",
	v087: "087",
	v088: "088",
	v089: "089",
	v090: "090",

	b01: "b01",
	vBUTTONS: "vButtons",
	deck: "deck",
}