import { r, externalReactions } from './externalReactions'
import { gameMap } from './gameMap'
import { locations, reactionTypes } from './map'

export const runValidations = () => {

	// console.log("Validate externalReactions.js...")
	const reactionIdArrays = Object.keys(r)
	reactionIdArrays.forEach((key) => {
		if (!externalReactions.some(reaction => reaction.id === r[key])) {
			console.error("ReactionID array in r variable not connected to an external reaction definition: ", r[key])
		}
	})

	reactionIdArrays.forEach((key) => {
		r[key].forEach(id => {
			if (!gameMap.some(node => (node.onEntryId === id || node.clickableObjects?.some(co => co.reactionsId === id)))) {
				console.error("Id string in r variable not found in any node entry or clickable object: ", id)
			}
		})
	})

	const existsInReactions = (id) => {
		return reactionIdArrays.some(key => r[key].some(rId => rId === id))
	}

	// console.log("Validate map.js...")
	const locationsArray = Object.keys(locations)
	locationsArray.forEach(location => {
		if (!gameMap.some(nodeVersion => nodeVersion.id === locations[location])) {
			console.error("Location not in locations varibable not present in gameMap.js: ", location, locations[location])
		}
	})

	// console.log("Validate gameMap.js...")
	gameMap.forEach(nodeVersion => {
		if (!nodeVersion.id) {
			console.error("Id not present in locations variable: ", nodeVersion)
		}

		if (!('version' in nodeVersion)) {
			console.error("Node missing version: ", nodeVersion)
		}

		if (!nodeVersion.imageURL) {
			console.error("Stated imageURL not present in viewBackgrounds variable", nodeVersion)
		}

		if (!nodeVersion.return) {
			console.error("Stated return node not present in locations variable", nodeVersion)
		}

		if (nodeVersion.onEntryId && !existsInReactions(nodeVersion.onEntryId)) {
			console.error("OnentryID in node not present in r variable: ", nodeVersion, nodeVersion.onEntryId)
		}

		nodeVersion.clickableObjects?.forEach(co => {
			if (!co.imageURL) {
				console.error("Stated imageURL for clickableObject not present in viewBackgrounds variable", nodeVersion, co)
			}

			if (co.reactionsId && !existsInReactions(co.reactionsId)) {
				console.error("ReactionsID in clickable object not present in r variable: ", nodeVersion, co, co.reactionsId)
			}

			if ('hoverImage' in co && !co.hoverImage) {
				console.error("HoverImage not present in hoverImages variable: ", nodeVersion, co, co.hoverImage)
			}

			if (!('hoverImage' in co)) {
				console.warn("No hoverImage specified for clickableObject: ", nodeVersion, co)
			}

			co.reactions && co.reactions.forEach(reaction => {
				if (!reaction.type) {
					console.error("Incorrect type for reaction: ", nodeVersion, co, reaction)
				} else {
					if (reaction.type === reactionTypes.navigate && !reaction.content) {
						console.error("Missing destination in navigation (not present in locations variable?): ", nodeVersion, co, reaction)
					}
				}

				if (!reaction.content) {
					console.error("Content missing: ", nodeVersion, co, reaction)
				}
			})
		})
	})

}