import { useState } from 'react'
import useContentSize from './useContentSize'
import { externalReactions } from './externalReactions'

export const states = {
	neutral: "neutral",
	hover: "hover",
	mouseDown: "mouseDown",
	nonClickable: "nonClickable",
}

const hoverImageGlobals = {
	height: 3,
	width: 3,
}

function getStyle(backgroundRatio, contentRatio, height, width, x, y, imageURL, state, isInProgress) {

	const all = {
		position: "absolute",
		cursor: ((state !== states.nonClickable) && !isInProgress) ? "pointer" : "",
		zIndex: "2",
		outline: "none",
	}

	const positioning = {

		height: `${height}%`,
		width: width ? `${width}%` : `${height * backgroundRatio / contentRatio}%`,

		left: `${x}%`,
		right: "",

		top: "",
		bottom: `${y}%`,
	}

	const backgroundImage = {

		backgroundImage: `url("${imageURL}")`,
		backgroundRepeat: "no-repeat",
		backgroundSize: state === states.nonClickable ? "100% 100%" : "200% 200%",

		backgroundPosition:
			state === states.nonClickable ?
				"0 0" :
				state === states.neutral ?
					"0 0" :
					state === states.hover ?
						`100% 0%` :
						state === states.mouseDown ?
							`100% 100%` :
							""
	}

	return { ...all, ...positioning, ...backgroundImage }
}

const ClickableObject = props => {
	const {
		backgroundRatio,
		height,
		x,
		y,
		imageURL,
		hoverImage,
		reactionsId,
		reactions,
		react,
		clickable,
		interrupted,
		width,
		post
	} = props

	const [hover, setHover] = useState(false)
	const [mouseDown, setMouseDown] = useState(false)
	const [internalProgress, setIsInProgress] = useState(false)

	const contentSize = useContentSize(imageURL)

	const isInProgress = internalProgress || interrupted

	var objecExternalReactions = externalReactions.find(reac => reac.id.some(id => id === reactionsId))?.reactions

	if (!objecExternalReactions)
		objecExternalReactions = []

	var totalReactions = reactions ? objecExternalReactions.concat(reactions) : objecExternalReactions

	// This is here so that a delay reaction will be properly respected. The delay logic needs to be present where the iteration happens.
	const reactOnAll = async () => {

		if (!isInProgress) {
			if (totalReactions && react) {

				setIsInProgress(true)

				for (const item of totalReactions) {
					if (item && item.type && post) {
						item.override = true
					}
					await react(item)
				}
				setIsInProgress(false)
			}
		}
	}

	return <>
		<div
			style={getStyle(backgroundRatio, contentSize.h / contentSize.w, height, width, x, y, imageURL, !clickable ? states.nonClickable : mouseDown ? states.mouseDown : hover ? states.hover : states.neutral, isInProgress)}
			tabIndex={-1}
			onClick={() => { clickable && reactOnAll() }}
			onMouseEnter={() => { clickable && !isInProgress && setHover(true) }}
			onMouseLeave={() => {
				setHover(false)
				setMouseDown(false)
			}}
			onMouseDown={() => { clickable && !isInProgress && setMouseDown(true) }}
			onMouseUp={() => setMouseDown(false)}
		>
			{hoverImage && hover && <div style={{
				position: "absolute",
				cursor: "",
				zIndex: "2",
				outline: "none",
				height: `${hoverImageGlobals.height}vw`,
				width: `${hoverImageGlobals.width}vw`,

				left: `calc(${50}% - ${hoverImageGlobals.width / 2}vw)`,
				right: "",

				top: "",
				bottom: `calc(${50}% - ${hoverImageGlobals.height / 2}vw)`,
				backgroundImage: `url("${hoverImage}")`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "100% 100%",
				backgroundPosition: "0 0",
				userSelect: 'none',
				WebkitUserSelect: 'none'
			}} />}
		</div>
	</>

}

export default ClickableObject