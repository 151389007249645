import { characters, reactionTypes, sounds, locations } from "./map"
import { itemDescription, doorOpen, dialogToNavigate, simpleDialog, complexDialog, runOnceCustom, simpleDialogProgress, customDialog } from "./ReactionBuilders"
import { runValidations } from "./Validations"

export const r = {
	navigationComputer: ["navigationComputer"],
	v05Patients: ["4"],
	v06Pills: ["5"],
	v09Sign: ["46"],
	v1922Portraits: ["8"],
	v35Cat: ["15"],
	v39artDescription: ["18"],
	v44solarSystemDescription: ["20"],
	v49ArcadeDescription: ["23"],
	v49ChestDescription: ["24"],
	v49VestsDescription: ["25"],
	v53Controls: ["16"],
	v55CameraDescription: ["29"],
	v63GateWayClosed: ["32"],
	v63WindowDescription: ["33"],
	v65GarbageMessDescription: ["35"],

	v15OpenDoorToV17: ["6"],
	v18OpenDoorToV16: ["7"],
	v28OpenDoortoV26: ["12"],
	v33OpenDoorToV35: ["14"],
	v36openDoorToV34: ["17"],
	v47OpenDoorToV49: ["22"],
	v50OpenDoorTov48: ["26"],
	v54OpenDoorTov55: ["28"],
	v56OpenDoorToV53: ["30"],
	v64OpenDoorToV62: ["34"],
	v66ElevatorToV85: ["backUpToJESO"],
	v74ElevatorToE03: ["39"],
	v75OpenDoorToV77: ["40"],
	v61OpenDoorToV63: ["v61OpenDoorToV63"],

	outOfFergusonIntoV76: ["outOfFergusonIntoV76"],
	doorIntoV32: ["doorIntoV32"],
	doorIntoV60: ["doorIntoV60"],
	doorIntoV42: ["doorIntoV42"],
	doorIntoV53: ["doorIntoV53"],


	toDeck: ["toDeck"],
	toSpecial: ["toSpecial"],
	toMainAlreadyThere: ["toMainAlreadyThere"],
	toBasement: ["toBasement"],
	backUpToEmptyElevator: ["backUpToEmptyElevator"],

	doorWontOpen: [
		"doorWontOpen",
		"21",
		"27",
		"47",
		"48",
		"doorToV87WontOpenInitial",
		"doorToV87WontOpenSecond",
		"doorToV85WontOpenInitial",
		"doorToV85WontOpenSecond",
	],

	entry_first_view: ["entry_first_view"],
	assistantInitial: ["assistantInitial"],
	sandersInitial: ["sandersInitial"],

	assistantNotLookGood: ["assistantNotLookGood"],
	sandersGivesP1: ["sandersGivesP1"],
	sandersHasGivenP1: ["sandersHasGivenP1"],

	assistantConfused: ["assistantConfused"],





	closedCleaning: ["closedCleaning"],



	doorIntoV83: ["doorIntoV83", "doorIntoV83Again"],
	butlerAskForPermit1: ["butlerAskForPermit1"],


	doorToV85Open: ["doorToV85Open"],

	jesoTravelToBasement: ["jesoTravelToBasement"],
	garyRequestsP3: ["garyRequestsP3"],

	doorToV87Open: ["doorToV87Open"], //,

	agentAskForP2: ["agentAskForP2"],

	// After Agent Request Permit 2
	openDinner: ["openDinner"],
	closedAfterDinner: ["closedAfterDinner"],

	dinnerPreReceiveP2: ["dinnerPreReceiveP2"],
	dinnerPostReceiveP2: ["dinnerPostReceiveP2"],

	// After Sanders give Permit 2
	agentTravelToSpecial: ["agentTravelToSpecial"],
	v77FergusonGivesPermit3: ["49"],



	// After Ferguson give Permit 3
	garyReceivesP3AndEnterTogether: ["garyReceivesP3AndEnterTogether"],
	garyleakIsFixedAndEnterTogether: ["garyleakIsFixedAndEnterTogether"],


	jesoNotSupposedToBeHere: ["jesoNotSupposedToBeHere"],



	fixTheLeak: ["clickLeakPreFix", "clickGaryInB01preFix"],
	fixLeakTransition: ["fixLeakTransition"],
	// After Leak Fix

	clickGaryInB01postFix: ["clickGaryInB01postFix"], // TODO
	assistantEvacuations: ["assistantEvacuations"],

	backUpToAgentFinal: ["backUpToAgentFinal"],
	outOfOperationEmergencyProtocol: ["outOfOperationEmergencyProtocol"],

	butlerTellDogStory: ["butlerTellDogStory"],
}

const simpleReactions = [
	itemDescription(r.navigationComputer, ["Navigation controls. Looks complicated."]),
	itemDescription(r.v06Pills, ["Bottles and pills. Better leave them to the patients."]),
	itemDescription(r.v1922Portraits, ["Portraits of people in uniform."]),
	itemDescription(r.v09Sign, ["The sign reads 187743 LEFT 384610 RIGHT."]),
	itemDescription(r.v35Cat, ["Meow."]),
	itemDescription(r.v53Controls, ["Can I navigate the ship from here?"]),
	itemDescription(r.v39artDescription, ["Is it some kind of art?"]),
	itemDescription(r.v44solarSystemDescription, ["A depiction of solar system UA281. Must be far away by now. . ."]),
	itemDescription(r.v49ArcadeDescription, ["An old-school arcade game."]),
	itemDescription(r.v49ChestDescription, ["Big chest. It's locked."]),
	itemDescription(r.v49VestsDescription, ["Some sort of vests are hanging from the rack."]),
	itemDescription(r.v55CameraDescription, ["You are being monitored."]),
	itemDescription(r.v63GateWayClosed, ["The gateway is closed. Better follow its request."]),
	itemDescription(r.v63WindowDescription, ["A window out to the stars. Space looks cold and beautiful."]),
	itemDescription(r.v65GarbageMessDescription, ["Wow, what a mess."]),
	itemDescription(r.closedAfterDinner, ["Keep out!"]),

	itemDescription(r.doorWontOpen, ["The door won't open..."]),

	doorOpen(r.v15OpenDoorToV17, locations.v017),
	doorOpen(r.v64OpenDoorToV62, locations.v062),
	doorOpen(r.v18OpenDoorToV16, locations.v016),
	doorOpen(r.v28OpenDoortoV26, locations.v026),
	doorOpen(r.v36openDoorToV34, locations.v034),
	doorOpen(r.v75OpenDoorToV77, locations.v077),
	doorOpen(r.v50OpenDoorTov48, locations.v048),
	doorOpen(r.v54OpenDoorTov55, locations.v055),
	doorOpen(r.v33OpenDoorToV35, locations.v035),
	doorOpen(r.v61OpenDoorToV63, locations.v063),
	doorOpen(r.v47OpenDoorToV49, locations.v049),
	doorOpen(r.v56OpenDoorToV53, locations.v053),
	doorOpen(r.doorIntoV83, locations.v083),
	doorOpen(r.doorToV87Open, locations.v087),
	doorOpen(r.doorToV85Open, locations.v085),
	doorOpen(r.openDinner, locations.v027),
	doorOpen(r.outOfFergusonIntoV76, locations.v076),
	doorOpen(r.doorIntoV32, locations.v032),
	doorOpen(r.doorIntoV60, locations.v060),
	doorOpen(r.doorIntoV42, locations.v042),
	doorOpen(r.doorIntoV53, locations.v053),

	dialogToNavigate(r.v74ElevatorToE03, characters.empty, [
		"The elevator takes you back to main floor.",
	], locations.v087, false),

	dialogToNavigate(r.v66ElevatorToV85, characters.empty, [
		"The elevator takes you upstairs.",
	], locations.v085, false),

	dialogToNavigate(r.backUpToAgentFinal, characters.empty, [
		"The elevator takes you back to main floor."
	], locations.v089, false),

	dialogToNavigate(r.jesoTravelToBasement, characters.jeso, [
		"Basement? Got a permit?",
		"Let me take a look.",
		"Great, thank you.",
		"Step on in, please.",
	], locations.v065, false),

	dialogToNavigate(r.agentTravelToSpecial, characters.agent, [
		"Thank you.",
		"This checks out.",
		"Elevator to Special floor. Step right in.",
	], locations.v073, false),

	dialogToNavigate(r.garyReceivesP3AndEnterTogether, characters.gary, [
		"Hey. You got the permit?",
		"Let me see.",
		"Great. Did you just come from Special?",
		"I feel like things are starting to get out of hand. . .",
		"OK, let's check out the leak you're talking about.",
		"Just go through the door. I'll come with you.",
	], locations.b01, false),

	dialogToNavigate(r.garyleakIsFixedAndEnterTogether, characters.gary, [
		"Yes? I think we dealt with it.",
		"Want to take another look?",
		"OK, let's go."
	], locations.b01, false),

	dialogToNavigate(r.toSpecial, characters.empty, [
		"You take the elevator to Special Floor.",
	], locations.v073, false),

	simpleDialog(r.toMainAlreadyThere, characters.empty, [
		"You are on main floor already.",
	]),

	dialogToNavigate(r.toBasement, characters.empty, [
		"You take the elevator to Basement.",
	], locations.v065, false),

	dialogToNavigate(r.backUpToEmptyElevator, characters.empty, [
		"You take the elevator to Main Floor.",
	], locations.v087, false),

	dialogToNavigate(r.toDeck, characters.empty, [
		"The elevator takes you to the star deck.",
	], locations.deck, false),

	simpleDialog(r.jesoNotSupposedToBeHere, characters.jeso, [
		"Special?",
		"No way.",
		"In fact, I am not supposed to be here.",
	]),
	simpleDialog(r.dinnerPostReceiveP2, characters.guest, [
		"This is great!",
		"Wait.",
		"Your crew license is not visible.",
		"Who are you?",
	]),
	simpleDialog(r.assistantInitial, characters.assistant, [
		"I need to start transmitting coordinates. . .",
	]),
	simpleDialog(r.sandersInitial, characters.sanders, [
		"Go ahead! There is no time to lose!",
	]),
	simpleDialog(r.sandersHasGivenP1, characters.sanders, [
		"We must work together.",
	]),
	simpleDialog(r.assistantConfused, characters.assistant, [
		"I am worried.",
		"Have you seen the captain?",
	]),
	simpleDialog(r.assistantNotLookGood, characters.assistant, [
		"Things do not look good. . ."
	]),
	simpleDialog(r.closedCleaning, characters.empty, [
		"It's closed! We're cleaning!",
	]),
	simpleDialog(r.assistantEvacuations, characters.assistant, [
		"The captain? I am afraid they seem to have left.",
		"I believe evacuations are ongoing, but I ---",
		"Actually, they might be in a meeting on Special.",
	]),

	simpleDialog(r.outOfOperationEmergencyProtocol, characters.agent, [
		"Elevators are out of operation.",
		"Part of emergency protocol.",
	]),

	// TODO :Validate
	simpleDialog(r.clickGaryInB01postFix, characters.gary, [
		"Yes, it is fixed now.",
		"I should get back to work.",
	]),


	complexDialog(r.v05Patients, [
		{
			character: characters.empty, text: [
				"Oh dear. They do not seem well.",
			]
		},
		{
			character: characters.patient, text: [
				"Ever since the doctors left, medicine is free!",
			]
		},
	], [], null, null),
]

const storyReactions = [

	// Intro at V1 Control Room
	runOnceCustom(r.entry_first_view, [
		{
			character: characters.assistant, text: [
				"Do you want me to relay our coordinates, captain?",
			]
		},
		{
			character: characters.sanders, text: [
				"Yes, and announce that we have three devices in cargo.",
				"Request arrangement of extravehicular handover.",
				"Do our computed trajectories match?",
			]
		},
		{
			character: characters.assistant, text: [
				"The checks are still running.",
			]
		},
		{
			character: characters.sanders, text: [
				"We will investigate hardware upgrades when we arrive at Alpha.",
				"Slow computation is interfering with ---",
				"Ah, you're finally here.",
				"We are seeing indications of a nitrogen leak downstairs, in the gauges hall.",
				"Please see to it that this gets handled.",
				"This is a crucial matter and there can be no delays in action.",
				"Handle it swiftly and with discretion.",
				"I can't believe the engineers do not prevent these situations from arising."
			]
		},
	], [], null, null, [{
		type: reactionTypes.startBackgroundTrack,
		content: sounds.leak,
	}], false),

	// Butler Asks for permit 1, this changes things
	simpleDialogProgress(
		r.butlerAskForPermit1,
		characters.butler,
		[
			"Good day. What floor would you like today?",
			"Basement? Special? Observeration deck?",
			"Basement?",
			"I am going to need a signed permit for that.",
		],
		[
			{ viewID: locations.v001, version: 1, desc: "Make Sanders give you permit." }
		]
	),

	complexDialog(r.sandersGivesP1, [
		{
			character: characters.sanders, text: [
				"It appears you have yet to repair the leak.",
				"There have been major complications with ship machinery since we last spoke.",
				"Absolute caution is necessary.",
				"We have received communications from Alpha that I do consider worrisome.",
				"As you know, the priority is crew safety. But, everything is political.",
				"We are maintaining our trajectory for now.",
				"Please be reminded that I expect your trust, just as you have mine.",
				"Here is your permit."
			]
		},
	], [
		{ viewID: locations.v031, version: 1, desc: "Lock door to v83 so you can't reach butler." },
		{ viewID: locations.v059, version: 1, desc: "Unlock door to v085 so you can reach JESO instead." },
		{ viewID: locations.v001, version: 2, desc: "Update v001 so that Sanders says something different" },
	],
		"PERMIT1", { viewID: locations.v001, version: 2 }),

	simpleDialogProgress(
		r.garyRequestsP3,
		characters.gary,
		[
			"How's it going?",
			"No, why should I let you in there? I have no idea who you are.",
			"There are pipes and stuff in there, better leave that to me.",
			"Now let me get back to ---",
			"Negative, I'm telling you.",
			"I have some responsibility here.",
			"If you want to get in you'll need approval from Ferguson.",
			"Where? I guess in their office up on Special.",
		],
		[
			{ viewID: locations.v041, version: 1, desc: "Opens door to V41 Where Agent needs permit" },
			{ viewID: locations.v085, version: 1, desc: "Jeso doesn't take you down anymore" },
			{ viewID: locations.v059, version: 2, desc: "Door to JESO wont open" },
		]
	),

	simpleDialogProgress(
		r.agentAskForP2,
		characters.agent,
		[
			"Stop right there.",
			"No.",
			"You will require a permit for access to Special.",
		],
		[
			{ viewID: locations.v001, version: 3, desc: "Updates control room so sanders isn't there" },
			{ viewID: locations.v025, version: 1, desc: "Unlock door to dining hall so sanders can give you Permit 2" },
		]
	),

	complexDialog(r.dinnerPreReceiveP2, [
		{
			character: characters.empty, text: [
				"The dinner guests are loudly cheering. . ."
			]
		},
		{
			character: characters.guest, text: [
				"I can't believe the machine actually worked!",
				"We are doomed, but we are rich!",
			]
		},
		{
			character: characters.sanders, text: [
				"What are you doing here? This is an executive meeting.",
				"Here is your permit. Tell Ferguson they're missing out.",
				"Please leave now.",
			]
		},
	], [
		{ viewID: locations.v087, version: 1, desc: "Agent accepts permit 2 at E3" },
		{ viewID: locations.v027, version: 1, desc: "Navigate to new version of dining room so conversation is different" },
	],
		"PERMIT2", { viewID: locations.v027, version: 1 }),

	complexDialog(r.v77FergusonGivesPermit3, [
		{
			character: characters.ferguson, text: [
				"Greetings. Yes, I heard.",
				"I apologize, but I cannot afford to pay full attention to all these situations, at the moment.",
				"These are trying times for all crew members.",
				"The ultimate responsibility is with me.",
				"I apologize for recent concerns regarding safety.",
				"I am sure Cpt. Sanders has filled you in.",
				"You are a hard worker and seem trustworthy to me.",
				"Of course, here is your permit for the relevant area.",
				"I trust you. Do not let us down.",
			]
		}
	], [
		{ viewID: locations.v087, version: 2, desc: "Agent disappears" },
		{ viewID: locations.v025, version: 2, desc: "Close dining room." },
		{ viewID: locations.v071, version: 1, desc: "Gary accepts permit 3." },
		{ viewID: locations.v066, version: 1, desc: "Elevator now goes to Selection" },
	], "PERMIT3", null),

	complexDialog(r.fixTheLeak, [
		{
			character: characters.gary, text: [
				"Yes, OK, that seems to be it. But. . .",
				"I've never seen argon levels like these before. . .",
				"There seems to be excess pressure in the entire machinery.",
			]
		},

	], [
		{ viewID: locations.b01, version: 1, desc: "Fix Leak" },
		{ viewID: locations.v071, version: 2, desc: "Gary says different." },
		{ viewID: locations.v001, version: 4, desc: "Update dialog at 01" },
		{ viewID: locations.v066, version: 2, desc: "Elevator now goes to E04" },
		{ viewID: locations.v083, version: 1, desc: "Agent at E04, no more elevator" },
		{ viewID: locations.v041, version: 2, desc: "Closing door to Agent elevator" },
		{ viewID: locations.v031, version: 2, desc: "Open Door to Butler" },
		{ viewID: locations.v083, version: 1, desc: "Make butler talk" },
	], null, { viewID: locations.b01, version: 1 }),

	runOnceCustom(r.fixLeakTransition, [
		{
			character: characters.empty, text: [
				"You have fixed the leak. See Cpt. Sanders for your reward."
			]
		},
	], [], "THELEAK", null, [], true),


	customDialog(r.butlerTellDogStory, [
		// customDialog(r.navigationComputer, [
		{
			character: characters.butler, text: [
				"Good day. How are you?",
				"Special floor? I am afraid that is off limits now.",
				"In fact, there is nowhere for us to go.",
				"This affair is quite sad. I find myself reminiscing. . .",
				"Many years ago, back on Two, we lived out in the woods.",
				"It was me and my partner in a small community of ex-pats.",
				"Our neighbor had this beautiful dog. . . ",
				"And we had so many apple trees growing.",
				"When the Expansion came, we had to leave it all behind.",
				"We made it off Two, all of us.",
				"Except for the dog. It was left behind. . .",
			]
		}
	], [], null, null, [{
		type: reactionTypes.fadeOut,
		content: "",
	}], [])
]


export const externalReactions = storyReactions
	.concat(simpleReactions)



runValidations()
