import MenuButtonBase from './MenuButtonBase'

const InMenusButton = props => {
	const {
		backgroundRatio,
		guiTallerThanScreen,
		height,
		width,
		x,
		y,
		label,
		fontSize,
		onClick
	} = props

	return <MenuButtonBase
		backgroundRatio={backgroundRatio}
		guiTallerThanScreen={guiTallerThanScreen}
		height={height}
		width={width}
		x={x}
		y={y}
		label={label}
		fontSize={fontSize}
		onClick={e => {
			e.stopPropagation()
			onClick()
		}}
	/>
}

export default InMenusButton