import { useState, useEffect } from 'react'

import { gameMap as map } from './gameMap'
import { cacheFirst } from './map'
import { reactionTypes, navigationDirection, locations } from './map'
import { externalReactions } from './externalReactions'
import Dialog from './Dialog'
import InGameButton from './InGameButton'

import GameBackground from './GameBackground'

const backButtonHeight = 13


const playSound = async soundURL => {
	if (soundURL) {

		var soundContent = await cacheFirst(soundURL)
		var audio = new Audio()
		audio.src = URL.createObjectURL(soundContent)
		audio.load()

		await audio.play()
	}
}

const loadImage = (imageURL) => {
	return new Promise((resolve, reject) => {
		let img = new Image()
		img.onload = () => resolve(true)
		img.onerror = reject
		img.src = imageURL
	})
}

const Game = props => {
	const { guiRatio, guiTallerThanScreen, performEndFadeOut, audioRef, playBackgroundMusic, fadestate } = props

	const [currentNode, setCurrentNode] = useState({})

	const [previousNode, setPreviousNode] = useState({})
	const [viewIndexChanges, setViewIndexChanges] = useState([])

	const [consumedReactions, setConsumedReactions] = useState([])

	const [dialogInProgress, setDialogInProgress] = useState(false)
	const [currentDialog, setCurrentDialog] = useState({})

	const [isFirstRun] = useState(true)

	useEffect(() => {
		if (isFirstRun && !(currentNode && currentNode.clickableObjects)) {
			navigate({ viewID: locations.v001 })
			audioRef.current.pause()
		}
	}, [isFirstRun])

	useEffect(() => {
		const setReturnValue = (e) => {
			(e || window.event).returnValue = true
		}

		window.addEventListener("beforeunload", setReturnValue)

		return () => {
			window.removeEventListener("beforeunload", setReturnValue)
		}
	}, [])

	const navigate = async props => {
		const { viewID, version } = props

		if (viewID !== currentNode.id) {
			setPreviousNode(currentNode)
		}

		var destinationVersion

		if (version) {
			destinationVersion = version
		} else if (viewIndexChanges.some(e => e.viewID === viewID)) {

			const nodeVersions = viewIndexChanges
				.filter(e => e.viewID === viewID)
				.sort((a, b) => b.version - a.version)

			destinationVersion = nodeVersions[0].version
		} else {
			destinationVersion = 0
		}

		const newNode = map.find(location => (location.id === viewID && location.version === destinationVersion))

		if (newNode) {
			if (newNode.interuptsBackgroundMusic) {
				audioRef.current.pause()
			}
			if (newNode.backgroundMusic) {
				playBackgroundMusic(newNode.backgroundMusic)
			}
			if (newNode.onEntry && newNode.onEntry.length > 0) {
				reactOnAll(newNode.onEntry)
			}
			if (newNode.onEntryId) {
				var currentReactions = externalReactions.find(reaction => reaction.id.some(id => id === newNode.onEntryId))
				reactOnAll(currentReactions.reactions)
			}

			await loadImage(newNode.imageURL)

			newNode.clickableObjects && newNode.clickableObjects
				.map(async clickableObject => await loadImage(clickableObject.imageURL))

			setCurrentNode(newNode)
		} else {
			console.error("No destination node.")
		}
	}

	const delay = async (duration) => {
		function sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms))
		}
		await sleep(duration)
	}

	const startDialog = content => {
		setDialogInProgress(true)
		setCurrentDialog(content)
	}

	const endDialog = () => {
		setDialogInProgress(false)
		setCurrentDialog({})
	}

	const changeNodeVersion = async content => {
		setViewIndexChanges(viewIndexChanges => [...viewIndexChanges, content])
	}

	const react = async props => {
		const { type, content, override } = props

		if (override || !dialogInProgress || type === reactionTypes.endDialog || type === reactionTypes.openMenu) {
			switch (type) {
				case reactionTypes.navigate:
					navigate({ viewID: content })
					break
				case reactionTypes.navigateVersion:
					navigate(content)
					break
				case reactionTypes.sound:
					playSound(content)
					break
				case reactionTypes.startBackgroundTrack:
					playBackgroundMusic(content)
					break
				case reactionTypes.delay:
					await delay(content)
					break
				case reactionTypes.runOnce:
					if (!consumedReactions.some(element => element === content.id)) {

						var nextReaction = content.reaction

						if (override) {
							nextReaction.override = true
						}

						await react(nextReaction)
						setConsumedReactions(consumedReactions => [...consumedReactions, content.id])
					}
					break
				case reactionTypes.startDialog:
					startDialog(content)
					break
				case reactionTypes.endDialog:
					endDialog()
					break
				case reactionTypes.changeViewIndex:
					changeNodeVersion(content)
					break
				case reactionTypes.fadeOut:
					performEndFadeOut()

					break
				default:
					console.error("No definition yet for reaction of type: ", type)
					break
			}
		}
	}


	const reactOnAll = async (reactions) => {
		if (reactions) {
			for (const item of reactions) {
				await react(item)
			}
		}
	}

	const backButton = <InGameButton
		backgroundRatio={guiRatio}
		guiTallerThanScreen={guiTallerThanScreen}
		height={backButtonHeight}
		width={backButtonHeight * 0.75}
		x={23.5 - (backButtonHeight / 2)}
		y={3}
		reactions={[{
			type: reactionTypes.navigate,
			content: currentNode.return !== navigationDirection.previous ?
				currentNode.return :
				previousNode.id
		}]}
		react={react}
		interrupted={dialogInProgress}
		label={"⟳"}
		fontSize={8}
	/>

	return currentNode && <>
		<GameBackground
			currentNode={currentNode}
			guiRatio={guiRatio}
			react={react}
			dialogInProgress={dialogInProgress || fadestate !== 'ingame'}
			scale={60.75}
			left={3}
			top={8.5}
		/>
		{
			dialogInProgress && currentDialog &&
			<Dialog
				{...currentDialog}
				guiRatio={guiRatio}
				useHeight={guiTallerThanScreen}
				react={react}
			/>
		}
		{
			!dialogInProgress && fadestate === 'ingame' &&
			backButton
		}
	</>
}

export default Game