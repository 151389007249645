/* eslint-disable no-restricted-globals */
import { precacheAndRoute } from 'workbox-precaching';
import { characters, imageURLs, hoverImages, sounds, talkSounds, viewBackgrounds } from './map'


// Establish a cache name
const cacheName = 'lbd-theleakgame-v1'
const cacheControlHeaderValue = 'public, max-age=31536000, immutable'

let images = {
	...imageURLs,
	...viewBackgrounds,
	...hoverImages,
	...sounds,
	...talkSounds
}

var resources = Object.keys(images).map(key => images[key])
var chars = Object.keys(characters).map(key => characters[key].avatar)

// Assets to precache
export const precachedAssets = [...new Set(resources.concat(chars).concat([
	'/attribution.txt',
	'/favicon.ico',
	'/logo192.png',
	'/logo512.png',
	'/ourattribution.txt',
	'/manifest.json',
	'/splashscreens',
	'/splashscreens/ipad_splash.png',
	'/splashscreens/ipadpro1_splash.png',
	'/splashscreens/ipadpro2_splash.png',
	'/splashscreens/ipadpro3_splash.png',
	'/splashscreens/iphone5_splash.png',
	'/splashscreens/iphone6_splash.png',
	'/splashscreens/iphoneplus_splash.png',
	'/splashscreens/iphonex_splash.png',
	'/splashscreens/iphonexr_splash.png',
	'/splashscreens/iphonexsmax_splash.png'
]))]

self.addEventListener('install', (event) => {
	// console.log("Install Service-worker")
	event.waitUntil(
		caches.open(cacheName).then((cache) => {
			// console.log(precachedAssets)
			const urlsToCache = [...new Set(precachedAssets)]
			// console.log(urlsToCache)
			const requests = urlsToCache.map(url =>
				new Request(url, { headers: { 'Cache-Control': cacheControlHeaderValue } })
			)

			// console.log(requests)
			return cache.addAll(requests)
		})
	);

	self.skipWaiting();
});

self.addEventListener('activate', function (event) {
	// console.log('Claiming control');
	return self.clients.claim();
});

self.addEventListener('fetch', (event) => {

	// console.log("BeforeFetch")
	event.respondWith(
		caches.match(event.request).then((response) => {
			// Serve from cache if available
			// console.log("SW Fetch response")
			if (response) {
				return response;
			}
			//Otherwise, fetch from the network with custom Cache-Control header
			return fetch(new Request(event.request, { headers: { 'Cache-Control': cacheControlHeaderValue } })).then(networkResponse => {
				return caches.open(cacheName).then((cache) => {
					// IMPORTANT: Clone the response. A response is a stream and because we want the browser to consume the response
					// as well as cache consuming the response, we need to clone it so we have two streams.
					cache.put(event.request, networkResponse.clone());
					return networkResponse;
				});
			});
		}).catch(error => {
			// Optional: Serve a fallback or handle errors when both cache and network fail
			console.error('Fetch failed; returning offline page instead.', error);
			return caches.match('/offline.html');
		})
	);
});
// console.log(self.__WB_MANIFEST)
precacheAndRoute(self.__WB_MANIFEST ? self.__WB_MANIFEST : []);

/* eslint-enable no-restricted-globals */