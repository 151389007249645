import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import InMenusButton from './InMenusButton'




const LaunchScreen = props => {
	const { guiRatio, guiTallerThanScreen, onClick, visible } = props

	const history = useHistory()

	return visible ? <div style={{
		zIndex: 10,
		display: 'block',
		position: 'absolute',
		height: '100%',
		top: 0,
		left: 0,
		right: 0,
		background: 'rgba(0, 0, 0, 0.85)',
	}}>
		<InMenusButton
			backgroundRatio={guiRatio}
			guiTallerThanScreen={guiTallerThanScreen}
			height={30}
			width={30}
			x={35}
			y={35}
			label={'Launch'}
			fontSize={4}
			onClick={onClick}
		/>
		<InMenusButton
			backgroundRatio={guiRatio}
			guiTallerThanScreen={guiTallerThanScreen}
			height={5}
			width={20}
			x={75}
			y={2.5}
			label={'Credits'}
			fontSize={3.5}
			onClick={() => history.push('/attribution')}
		/>
	</div> : <></>
}

export default LaunchScreen