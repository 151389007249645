import React, { useEffect, useState } from 'react'
import { imageURLs, sounds } from './map'
import MenuLabel from './MenuLabel'
import useContentSize from './useContentSize'
import useWindowSize from './useWindowSize'
import AttributionPage from './AttributionPage'

const durations = {
	menuItemsFadeOutGameStart: 1.5 * 1000,
	backgroundFadeOutGameStart: 8 * 1000,
}

const endDurations = {
	gameFadeOut: 4 * 1000,
	dogFadeIn: 4 * 1000,
	dogStay: 5 * 1000,
	dogFadeOut: 4 * 1000,
	theEndFadeIn: 5 * 1000,
	theEndStay: 4 * 1000,
	thEndFadeOut: 3 * 1000,
	creditsFadeIn: 3 * 1000,
	creditsStay: 5 * 1000,
	creditsFadeOut: 3 * 1000,
}

const aggrregateDuration = (durations, index) => {
	var total = 0

	for (var key in durations) {
		if (key === index)
			break
		else
			total += durations[key]
	}

	return total
}

export const getFadeCSS = (fadeIn, duration) => {

	return !fadeIn ? {
		visibility: 'hidden',
		opacity: 0,
		transition: `visibility 0s ${duration}ms, opacity ${duration}ms linear`,
	} : {
		visibility: 'visible',
		opacity: 1,
		transition: `opacity ${duration}ms linear`,
	}
}

const getStyle = (guiRatio, windowRatio, guiDimension, guiState) => {
	const base = {

		backgroundColor: "gray",
		overflow: "hidden",
		position: "absolute",
		maxWidth: "100%",
		maxHeight: "100%",
		width: guiRatio < windowRatio ? "100%" : `calc(100vh * ${guiDimension.w} / ${guiDimension.h})`,
		height: guiRatio > windowRatio ? "100%" : `calc(100vw * ${guiDimension.h} / ${guiDimension.w})`,
		margin: "auto",
		inset: "0 0 0 0",
		backgroundImage: `url("${guiState.background}")`,
		backgroundSize: "100%",
		backgroundPosition: "center center",
		backgroundRepeat: "no-repeat",
	}

	const fading = guiState.fadeOut || guiState.fadeIn ? getFadeCSS(guiState.fadeIn, guiState.duration) : {}

	return { ...base, ...fading }
}

const getChildrenStyle = (guiRatio, windowRatio, guiDimension, guiState) => {
	const base = {
		backgroundColor: "transparent",
		overflow: "hidden",
		position: "absolute",
		maxWidth: "100%",
		maxHeight: "100%",
		width: guiRatio < windowRatio ? "100%" : `calc(100vh * ${guiDimension.w} / ${guiDimension.h})`,
		height: guiRatio > windowRatio ? "100%" : `calc(100vw * ${guiDimension.h} / ${guiDimension.w})`,
		margin: "auto",
		inset: "0 0 0 0",
	}

	const fading = guiState.fadeOut || guiState.fadeIn ? getFadeCSS(guiState.fadeIn, guiState.duration) : {}

	return { ...base, ...fading }
}

const Gui = props => {

	const { background, playBackgroundMusic, audioRef, children } = props

	const [displayState, setDisplayState] = useState({
		fadeIn: false,
		fadeOut: false,
		duration: 0,
		background: background,
		mode: 'ingame'
	})

	const [childrenState, setChildrenState] = useState({
		fadeIn: false,
		fadeOut: false,
		duration: 0,
		mode: 'ingame'
	})

	useEffect(() => {
		setDisplayState({
			fadeIn: false,
			fadeOut: false,
			duration: 0,
			background: background,
			mode: 'ingame'
		})
	}, [background])

	const fadeGameStart = fade => {

		setChildrenState({
			fadeIn: false,
			fadeOut: fade,
			duration: fade ? durations.menuItemsFadeOutGameStart : 0,
		})

		setDisplayState(prev => {
			return {
				fadeIn: false,
				fadeOut: fade,
				duration: endDurations.gameFadeOut,
				background: prev.background,
				mode: 'fadeout'
			}
		})
	}

	const [windowWidth, windowHeight] = useWindowSize()
	const guiDimension = useContentSize(background)

	const [visible, setVisible] = useState(true)
	const windowRatio = windowHeight / windowWidth
	const guiRatio = guiDimension.h / guiDimension.w

	const endStates = [
		{
			section: "GameFadeOut",
			preAction: () => audioRef && audioRef.current.pause(),
			displayState: {
				background: background,
				fadeIn: false,
				fadeOut: true,
				duration: endDurations.gameFadeOut,
				mode: 'gamefadeout'
			},
			postAction: null,
			playtime: 0,
		},
		{
			section: "DogFadeIn",
			preAction: () => playBackgroundMusic(sounds.themesong),
			displayState: {
				background: imageURLs.dog,
				fadeIn: true,
				fadeOut: false,
				duration: endDurations.dogFadeIn,
				mode: 'dogfadein'
			},
			postAction: () => setVisible(false),
			playtime: aggrregateDuration(endDurations, "dogFadeIn"),
		},
		{
			section: "DogFadeOut",
			preAction: null,
			displayState: {
				background: imageURLs.dog,
				fadeIn: false,
				fadeOut: true,
				duration: endDurations.dogFadeOut,
				mode: 'dogfadeout'
			},
			postAction: null,
			playtime: aggrregateDuration(endDurations, "dogFadeOut"),
		},
		{
			section: "theEnd",
			preAction: null,
			displayState: {
				background: imageURLs.black,
				fadeIn: true,
				fadeOut: false,
				duration: endDurations.theEndFadeIn,
				mode: 'theend'
			},
			postAction: null,
			playtime: aggrregateDuration(endDurations, "theEndFadeIn"),
		},
		{
			section: "theEndStay",
			preAction: null,
			displayState: {
				background: imageURLs.black,
				fadeIn: false,
				fadeOut: true,
				duration: endDurations.thEndFadeOut,
				mode: 'theend'
			},
			postAction: null,
			playtime: aggrregateDuration(endDurations, "theEndStay"),
		},
		{
			section: "creditsFadeIn",
			preAction: null,
			displayState: {
				background: imageURLs.black,
				fadeIn: true,
				fadeOut: false,
				duration: endDurations.creditsFadeIn,
				mode: 'credits'
			},
			postAction: null,
			playtime: aggrregateDuration(endDurations, "creditsFadeIn"),
		}
	]

	const performEndFadeOut = () => {
		endStates.forEach(endstate => {
			setTimeout(() => {
				!!endstate.preAction && endstate.preAction()

				setDisplayState(endstate.displayState)

				!!endstate.postAction && endstate.postAction()
			}, endstate.playtime)
		})
	}

	return <div style={getStyle(guiRatio, windowRatio, guiDimension, displayState)}>
		<div style={getChildrenStyle(guiRatio, windowRatio, guiDimension, childrenState)}>
			{visible && React.Children.map(children, child => React.isValidElement(child) && React.cloneElement(child, { guiTallerThanScreen: guiRatio > windowRatio, guiRatio: guiRatio, fadeOut: displayState.fadeOut, fadeGameStart, performEndFadeOut, audioRef, playBackgroundMusic, fadestate: displayState.mode }))}

			{displayState.mode === 'theend' ?
				<MenuLabel
					backgroundRatio={guiRatio}
					guiTallerThanScreen={guiRatio > windowRatio}
					height={15}
					width={40}
					x={30}
					y={45}
					label={'The End'}
				/>
				: displayState.mode === 'credits' ?
					<AttributionPage guiRatio guiTallerThanScreen={guiRatio > windowRatio} /> : <></>
			}
		</div>
	</div>
}


export default Gui