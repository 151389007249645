import { useState, useEffect, useRef } from "react"
import ClickableObject from "./ClickableOject"

import { talkSounds, cacheFirst } from "./map"
import InGameButton from "./InGameButton"

const speakTime = 20
const objectDescriptionTime = 7

const randomSelection = (lastRandom) => {
	var newRandom = lastRandom

	while (newRandom === lastRandom) {
		newRandom = Math.floor(Math.random() * 4)
	}

	return newRandom
}

const Dialog = props => {
	const { dialogs, postActions, guiRatio, useHeight, react } = props

	const [dialogIndex, setDialogIndex] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)
	const [cursorIndex, setCursorIndex] = useState(1)

	const [lastAudioIndex, setLastAudioIndex] = useState(0)
	const audioRef = useRef(new Audio())

	const dialog = dialogs && dialogs[dialogIndex] ? dialogs[dialogIndex] : {}
	const { text, avatar, name } = dialog

	const sounds = [talkSounds.talk1, talkSounds.talk2, talkSounds.talk3, talkSounds.talk4]

	const playSound = async () => {
		var random = randomSelection(lastAudioIndex)
		var sound = sounds[random]
		try {
			if (sound) {
				audioRef.current.readyState > 0 && !audioRef.current.paused && audioRef.current.pause()

				var soundContent = await cacheFirst(sound)
				audioRef.current = new Audio()
				audioRef.current.src = URL.createObjectURL(soundContent)
				audioRef.current.load()
			}
			await audioRef.current.play()
		} catch (e) {
			console.error("Unable to play Audio.")
		}
		setLastAudioIndex(random)
	}

	useEffect(() => {
		const moveCursor = () => {
			if (text && cursorIndex < text[pageIndex].length) {
				setCursorIndex(cursorIndex + 1)
			}
		}

		const timer = setInterval(() => moveCursor(), name ? speakTime : objectDescriptionTime)

		return () => clearInterval(timer)
	}, [text, cursorIndex, pageIndex, name])

	useEffect(() => {
		if (name) {
			playSound()
		}
	}, [dialogs])


	const onNext = () => {
		// If cursor not all the way down, place it all the way down
		// If it is all the way down, go to next page.
		if (text && cursorIndex < text[pageIndex].length) {
			setCursorIndex(text[pageIndex].length)
		} else if (text) {
			setCursorIndex(1)

			if (pageIndex < text.length - 1) {
				setPageIndex(pageIndex => pageIndex + 1)
				name && playSound()
			} else {
				setPageIndex(0)
				dialogs[dialogIndex + 1].name && playSound()
				setDialogIndex(dialogIndex => dialogIndex + 1)
			}
		}
	}

	const isDone = () => {
		if (dialogs && text) {
			if (dialogIndex < dialogs.length - 1) {
				return false
			}

			if (pageIndex < text.length - 1) {
				return false
			}

			if (cursorIndex <= text[pageIndex].length - 1) {
				return false
			}

			return true
		} else {
			return false
		}
	}

	const notDone = !isDone()

	const fontSizes = {
		name: 4,
		text: 2.5,
		padding: 1,
	}

	const avatarDefinition = {
		backgroundRatio: guiRatio,
		height: 20.5,
		x: 83.5,
		y: 69.5,
		imageURL: avatar ? avatar : "",
		clickable: false,
	}

	const textObjectStyling = {
		left: "66.5%",
		bottom: "29.5%",

		width: "29.5%",
		height: "24.5%",
		userSelect: 'none',
		WebkitUserSelect: 'none',
		position: "absolute",
		outline: "none",
		color: "#afafaf",
		fontSize: useHeight ? `${fontSizes.text}vh` : `${fontSizes.text * guiRatio}vw`,
		padding: useHeight ? `${fontSizes.padding}vh` : `${fontSizes.padding * guiRatio}vw`,
		fontFamily: "'Press Start 2P'"
	}

	const nameObjectStyling = {
		left: "66.5%",
		bottom: "59%",

		width: "29.5%",
		height: "4.5%",

		position: "absolute",
		outline: "none",
		color: "#afafaf",
		userSelect: 'none',
		WebkitUserSelect: 'none',
		fontSize: useHeight ? `${fontSizes.name}vh` : `${fontSizes.name * guiRatio}vw`,
		padding: useHeight ? `${fontSizes.padding}vh` : `${fontSizes.padding * guiRatio}vw`,
		fontFamily: "'Press Start 2P'"
	}


	const nextPostActions = postActions ? [{ type: "endDialog", content: "now!" }, ...postActions] : [{ type: "endDialog", content: "now!" }]

	const avatarObject = avatar ? <ClickableObject {...avatarDefinition} interrupted={false} /> : <></>

	const nextButton = <InGameButton
		backgroundRatio={guiRatio}
		guiTallerThanScreen={useHeight}
		height={7}
		width={7}
		x={89.45}
		y={19}
		reactions={notDone ? "1" : nextPostActions}
		react={notDone ? onNext : react}
		interrupted={false}
		post={true}
		label={notDone ? "→" : "OK"}
		fontSize={4}
	/>

	const textObject = <div key={dialogIndex + pageIndex + cursorIndex} style={textObjectStyling}>{text && text[pageIndex] && cursorIndex && text[pageIndex].substring(0, cursorIndex)}</div>
	const nameObject = dialogs && dialogs[dialogIndex] && dialogs[dialogIndex].name ? <div style={nameObjectStyling}>{dialogs[dialogIndex].name}</div> : <></>

	return <>
		{nameObject}
		{avatarObject}
		{textObject}
		{nextButton}
	</>
}

export default Dialog